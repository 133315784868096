import React, { useEffect } from 'react';
import { useSelector } from '../hooks/useSelector';
import { useActions } from '../hooks/useActions';

import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Community from '../components/Community';
import Overview from '../components/Overview';
import Footer from '../components/Footer';

const Home: React.FC = () => {
  const { resetItem } = useActions();

  const { currentLocation } = useSelector(state => state.location);
  const { council } = useSelector(state => state.council);
  const { item } = useSelector(state => state.item);

  useEffect(() => {
    if (currentLocation && council && item) resetItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <header>
        <Navbar />
        <Hero />
      </header>
      <main>
        <Features />
        <Overview />
        <Community />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Home;
