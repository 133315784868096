import { FC } from 'react';
import styled from 'styled-components';
import CharityShop from '../../../assets/images/charity-shop.svg';
import CompostingHub from '../../../assets/images/composting-hub.svg';
import ContainerDeposit from '../../../assets/images/container-deposit.svg';
import RecycleStation from '../../../assets/images/recycle-station.svg';

const Key: FC = () => {
  return (
    <Container>
      <KeyItem>
        <img src={RecycleStation} alt="Recycling location" />
        <span>Recycling location</span>
      </KeyItem>
      <KeyItem>
        <img src={ContainerDeposit} alt="Container deposit" />
        <span>Container deposit</span>
      </KeyItem>
      <KeyItem>
        <img src={CompostingHub} alt="Compost hub" />
        <span>Compost hub</span>
      </KeyItem>
      <KeyItem>
        <img src={CharityShop} alt="Charity shop" />
        <span>Charity shop</span>
      </KeyItem>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const KeyItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 32px;
    height: 32px;
  }

  span {
    white-space: nowrap;
    color: #fff;
    margin-left: 10px;
    text-shadow: -0.6px -0.6px 0 #000, 0.6px -0.6px 0 #000, -0.6px 0.6px 0 #000, 0.6px 0.6px 0 #000;
  }
`;

export default Key;
