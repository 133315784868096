import axios from 'axios';
import { Dispatch } from 'redux';
import { councilSelected } from '../../api';
import { BinLid } from '../../utils/helpers';
import { CouncilActionType, LocationActionType } from '../action-types';
import { CouncilAction, LocationAction } from '../actions';
import { BinSystem, BinSystemCandidates, Council, CouncilCandidates } from '../types';

export const setCouncil = (council: Council) => async (dispatch: Dispatch<CouncilAction>) => {
  if (council.id) {
    councilSelected(council.id, 'address');
  }

  dispatch({
    type: CouncilActionType.SET_COUNCIL,
    payload: council,
  });

  if (council.binSystemCandidates) {
    // Ensure the 'None' option is always last.
    council.binSystemCandidates.sort((a, b) => {
      if (a[0].binType === BinLid.NONE) {
        return 1;
      }

      if (b[0].binType === BinLid.NONE) {
        return -1;
      }

      return 0;
    });

    dispatch({
      type: CouncilActionType.SET_BIN_SYSTEM_CANDIDATES,
      payload: council.binSystemCandidates,
    });
  } else if (council.binSystem) {
    dispatch({
      type: CouncilActionType.SET_BIN_SYSTEM,
      payload: council.binSystem,
    });
  } else {
    dispatch({
      type: CouncilActionType.GET_COUNCIL_ERROR,
      payload: 'Unable to find your bin system. Please try a different location',
    });
  }
};

export const setCouncilCandidates =
  (councilCandidates: CouncilCandidates) => async (dispatch: Dispatch<CouncilAction>) => {
    dispatch({
      type: CouncilActionType.SET_COUNCIL_CANDIDATES,
      payload: councilCandidates,
    });
  };

export const setBinSystem = (binSystem: BinSystem) => async (dispatch: Dispatch<CouncilAction>) => {
  dispatch({
    type: CouncilActionType.SET_BIN_SYSTEM,
    payload: binSystem,
  });
};

export const setBinSystemCandidates =
  (binSystemCandidates: BinSystemCandidates) => async (dispatch: Dispatch<CouncilAction>) => {
    dispatch({
      type: CouncilActionType.SET_BIN_SYSTEM_CANDIDATES,
      payload: binSystemCandidates,
    });
  };

export const getCouncil = (locationLatLng: any) => async (dispatch: Dispatch<CouncilAction | LocationAction>) => {
  dispatch({
    type: CouncilActionType.GET_COUNCIL_PENDING,
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/location`,
      locationLatLng,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      },
    );

    if (res.data.postcode) {
      dispatch({
        type: LocationActionType.SET_CURRENT_LOCATION_POSTCODE,
        payload: res.data.postcode,
      });
    }

    // Append a 'council not shown' option.
    const emptyCouncilOption = [{ name: "My council isn't shown", id: null }];
    if (res.data.councilCandidates) {
      dispatch({
        type: CouncilActionType.SET_COUNCIL_CANDIDATES,
        payload: [...res.data.councilCandidates, ...emptyCouncilOption],
      });
    } else if (res.data.council) {
      dispatch({
        type: CouncilActionType.SET_COUNCIL_CANDIDATES,
        payload: [res.data.council, ...emptyCouncilOption],
      });
    } else {
      dispatch({
        type: CouncilActionType.GET_COUNCIL_ERROR,
        payload: 'Unable to find your council. Please try a different location',
      });
    }
  } catch (error: any) {
    dispatch({
      type: CouncilActionType.GET_COUNCIL_ERROR,
      payload: error.message,
    });
  }
};

export const resetCouncil = () => async (dispatch: Dispatch<CouncilAction>) => {
  dispatch({
    type: CouncilActionType.RESET_COUNCIL,
  });
};
