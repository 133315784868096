import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Link as ReactScrollLink } from 'react-scroll';
import Logo from '../assets/images/Logo.svg';
import Theme from '../utils/theme';

const MainContainer = styled.div`
  width: 50vw;
  height: 100%;
  background-color: #2f393f;
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 40px;

  > * {
    margin-bottom: 14px;
  }
`;

const DownloadButton = styled.button`
  font-family: ${Theme.Fonts.SANS_1_700};
`;

const LogoImg = styled.img`
  width: 178px;
  height: 58px;

  @media screen and (max-width: 768px) {
    width: 135px;
    height: 44px;
  }
`;

interface Props {
  onMenuRequestClose: () => void;
  isHome: boolean;
}

function NavbarMenu({ onMenuRequestClose, isHome }: Props) {
  return (
    <MainContainer>
      <LogoImg src={Logo} alt="Recycle Mate" />
      <Nav>
        {!isHome ? (
          <Link to="/">
            <div onClick={onMenuRequestClose}>Home</div>
          </Link>
        ) : (
          <>
            <Link to="/">
              <div onClick={onMenuRequestClose}>Home</div>
            </Link>
            <ReactScrollLink to="overview" smooth={true}>
              <div onClick={onMenuRequestClose}>Overview</div>
            </ReactScrollLink>
            <ReactScrollLink to="features" smooth={true}>
              <div onClick={onMenuRequestClose}>Features</div>
            </ReactScrollLink>
            <Link to={{ pathname: 'https://kb.recyclemate.com.au' }} target="_blank">
              Knowledge Base
            </Link>

            <ReactScrollLink to="overview" smooth={true}>
              <DownloadButton className="btn bg-lightgreen" onClick={onMenuRequestClose}>
                Download app
              </DownloadButton>
            </ReactScrollLink>
          </>
        )}
      </Nav>
    </MainContainer>
  );
}

export default NavbarMenu;
