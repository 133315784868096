import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ChevronForwardIcon from '../assets/images/ChevronForwardIcon.svg';
import MapPinIcon from '../assets/images/MapPinIcon.svg';
import DisposalLocationDetailsModal from '../components/DisposalLocationDetailsModal';
import { useActions } from '../hooks/useActions';
import { useSelector } from '../hooks/useSelector';
import { DisposalLocation } from '../state/types';
import { Place } from '../types';
import { haversineDistance } from '../utils/helpers';
import Theme from '../utils/theme';
import PlaceImage from './PlaceImage';

const DisposalLocations: React.FC = () => {
  const { setDisposalLocationToView } = useActions();
  const { loading, disposalLocations } = useSelector(state => state.item);
  const coords = useSelector(state => state.location.currentLocation?.geometry.location);

  const sortedDisposalLocations = useMemo(
    () =>
      // Create a copy of the array so that we don't mutate the original.
      [...disposalLocations].sort((a, b) => {
        const msgA = a.facilityMessage;
        const msgB = b.facilityMessage;

        if (msgA === 'Dispose here' && msgB !== 'Dispose here') {
          return 1;
        } else if (msgA !== 'Dispose here' && msgB === 'Dispose here') {
          return -1;
        }

        return 0;
      }),
    [disposalLocations],
  );

  const viewDisposalLocationDetails = (key: number) => {
    const disposalLocation = sortedDisposalLocations[key];

    setDisposalLocationToView(disposalLocation);
  };

  const distance = useCallback(
    ({ location }: DisposalLocation) => {
      if (!coords || !location?.coordinates) {
        return '';
      }

      const { lat, lng } = typeof coords.lng === 'function' ? { lat: coords.lat(), lng: coords.lng() } : coords;

      // @ts-ignore
      const d = haversineDistance([lng, lat], location.coordinates);

      console.log({ d, coords, location });

      return (d / 1000).toFixed(1) + 'km';
    },
    [coords],
  );

  return (
    <DisposalLocationsContainer>
      <DisposalLocationsTitle className="color-darker">Alternative Disposal Locations</DisposalLocationsTitle>
      {disposalLocations.length === 0 && !loading && <p className="p-smaller color-dark">Unavailable for this item</p>}
      {sortedDisposalLocations.map((disposalLocation, key) => (
        <CollectionCentre key={`DL-${key}`}>
          {!!coords && (
            <Distance>
              <img src={MapPinIcon} alt="" />
              {distance(disposalLocation)}
            </Distance>
          )}
          <LocationPreview place={disposalLocation as Place} transparent />
          <LocationName>{disposalLocation.name}</LocationName>
          <Link to="#" className="text-link p-smallest" onClick={() => viewDisposalLocationDetails(key)}>
            <ViewDetails>
              {disposalLocation?.facilityMessage ?? 'View Details'} &nbsp;&nbsp;
              <img src={ChevronForwardIcon} alt="ChevronForwardIcon" />
            </ViewDetails>
          </Link>
        </CollectionCentre>
      ))}
      <DisposalLocationDetailsModal />
    </DisposalLocationsContainer>
  );
};

const DisposalLocationsContainer = styled.div`
  margin-bottom: 50px;
`;

const DisposalLocationsTitle = styled.p`
  font-family: ${Theme.Fonts.SANS_1_900};
  margin-bottom: 30px;
`;

const LocationName = styled.p`
  font-family: ${Theme.Fonts.SANS_1_900};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  max-width: 70%;
`;

const CollectionCentre = styled.div`
  background-color: #2f393f;
  color: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 21px;
  padding-left: 150px;
  position: relative;
  text-align: left;

  @media screen and (max-width: 768px) {
    padding-left: 18px;
  }
`;

const ViewDetails = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  font-family: ${Theme.Fonts.SANS_1_900};
  font-size: 0.9rem;
`;

const LocationPreview = styled(PlaceImage)`
  width: 129px;
  height: 81px;
  float: left;
  left: 20px;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  left: 5px;
  top: 5px;
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Distance = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
`;

export default DisposalLocations;
