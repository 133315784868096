import { geocodeByAddress, geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
import { Dispatch } from 'redux';
import { LocationActionType } from '../action-types';
import { LocationAction } from '../actions';
import { Location } from '../types';

export const searchLocation = (locationSearchTerm: string) => async (dispatch: Dispatch<LocationAction>) => {
  if (locationSearchTerm.length < 2) return;

  dispatch({
    type: LocationActionType.SEARCH_LOCATION_PENDING,
  });

  try {
    const res = await geocodeByAddress(`${locationSearchTerm} , Australia`);

    dispatch({
      type: LocationActionType.SEARCH_LOCATION_SUCCESS,
      payload: res,
    });
  } catch (error: any) {
    dispatch({
      type: LocationActionType.SEARCH_LOCATION_ERROR,
      payload: error.message,
    });
  }
};

export const searchPlaceById =
  (placeId: string, getCouncil: (location: any) => void) => async (dispatch: Dispatch<LocationAction>) => {
    dispatch({
      type: LocationActionType.SEARCH_LOCATION_PENDING,
    });

    try {
      const result = await geocodeByPlaceId(placeId);
      const location = getLocationData(result);

      dispatch({
        type: LocationActionType.SET_CURRENT_LOCATION,
        payload: result[0],
      });

      getCouncil(location);
    } catch (error: any) {
      dispatch({
        type: LocationActionType.SEARCH_LOCATION_ERROR,
        payload: error.message,
      });
    }
  };

export const setCurrentLocation = (location: Location) => async (dispatch: Dispatch<LocationAction>) => {
  dispatch({
    type: LocationActionType.SET_CURRENT_LOCATION,
    payload: location,
  });
};

export const resetLocation = () => async (dispatch: Dispatch<LocationAction>) => {
  dispatch({
    type: LocationActionType.RESET_LOCATION,
  });
};

export const getLocationByLatLng = (latLng: any) => async (dispatch: Dispatch<LocationAction>) => {
  try {
    const res = await geocodeByLatLng(latLng);
    dispatch({
      type: LocationActionType.SET_CURRENT_LOCATION,
      payload: res[0],
    });
  } catch (error: any) {}
};

const getLocationData = (res: google.maps.GeocoderResult[]) => {
  const postcode = res[0]?.address_components.find(v => v.types.includes('postal_code'))?.long_name;
  const { lat, lng } = res[0]?.geometry.location;

  return { postcode, lat, lng };
};

export const setResetSearchLocation = () => async (dispatch: Dispatch<LocationAction>) => {
  dispatch({
    type: LocationActionType.RESET_SEARCH_LOCATION,
  });
};
