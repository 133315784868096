import React from 'react';
import styled from 'styled-components';

import Search from './Search';

import HeroGirl from '../assets/images/HeroGirl.png';

const Hero: React.FC = () => {
  return (
    <HeroContainer id="home">
      <HeroInnerContainer>
        <Search />
      </HeroInnerContainer>
      <HeroGirlImg src={HeroGirl} alt="Hero" />
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  width: 100%;
  background-color: #2f393f;
  height: 684px;
  position: relative;

  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

const HeroInnerContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  color: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding-bottom: 0px;
  }
`;

const HeroGirlImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 332px;

  @media screen and (min-width: 769px) and (max-width: 1240px) {
    width: 280px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export default Hero;
