import { FC, useCallback } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import ModalCloseIcon from '../assets/images/ModalCloseIcon.svg';
import { Place } from '../types';
import Theme from '../utils/theme';
import _PlaceImage from './PlaceImage';

export type PlaceModalProps = {
  place?: Place;
  visible?: boolean;
  onRequestClose?: () => void;
};

const PlaceModal: FC<PlaceModalProps> = ({ place, visible = false, onRequestClose }) => {
  const onAddressClick = useCallback(
    () => window.open(`https://www.google.com/maps/place/?q=place_id:${place?.placeId}`, '_blank'),
    [place?.placeId],
  );

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onRequestClose}
      className="modal modal-recycle"
      overlayClassName="modal-overlay"
      contentLabel="Disposal Location"
      ariaHideApp={false}>
      <Container>
        <CloseButton src={ModalCloseIcon} onClick={onRequestClose} />
        <PlaceImage place={place} />
        <CentreDetails>
          <LocationName>{place?.name}</LocationName>
          <ContactHours>
            <Contact>
              {!!place?.address && (
                <ContactMode>
                  <SectionTitle className="p-smaller">Address</SectionTitle>
                  <a
                    href={'https://www.google.com/maps/place/' + place.address}
                    className="text-link"
                    target="_blank"
                    rel="noreferrer">
                    <p className="p-smallest">{place.address}</p>
                  </a>
                  <DirectionsButton onClick={onAddressClick}>Directions</DirectionsButton>
                </ContactMode>
              )}
              {!!place?.phone && (
                <ContactMode>
                  <SectionTitle className="p-smaller">Phone</SectionTitle>
                  <a href={'tel:' + place.phone} className="text-link">
                    <p className="p-smallest">{place.phone}</p>
                  </a>
                </ContactMode>
              )}
              {!!place?.website && (
                <ContactMode>
                  <SectionTitle className="p-smaller">Website</SectionTitle>
                  <a href={place.website} className="text-link" target="_blank" rel="noreferrer">
                    <p className="p-smallest">
                      {place.website.length < 50 ? place.website : place.website.substring(0, 50)}
                    </p>
                  </a>
                </ContactMode>
              )}
            </Contact>
            {!!place?.openingHours && (
              <Hours className="p-smaller">
                <SectionTitle className="SectionTitle-smaller">Open hours</SectionTitle>
                <table className="color-light">
                  <tbody>
                    {place.openingHours.map((detail, key) => (
                      <tr key={`TR-${key}`}>
                        <OpenHoursDetail key={`OH-${key}`}>{detail}</OpenHoursDetail>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Hours>
            )}
          </ContactHours>
        </CentreDetails>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const PlaceImage = styled(_PlaceImage)`
  width: 100%;
  height: 262px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 2;
`;

const CentreDetails = styled.div`
  text-align: left;
  padding: 40px 30px;
`;

const LocationName = styled.h4`
  font-family: ${Theme.Fonts.SANS_1_900};
`;

const ContactHours = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Contact = styled.div`
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ContactMode = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.p`
  font-family: ${Theme.Fonts.SANS_1_900};
`;

const Hours = styled.div`
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const OpenHoursDetail = styled.td`
  padding-right: 20px;
`;

const DirectionsButton = styled.div`
  padding: 10px 20px;
  border-radius: 30px;
  background: rgb(47, 57, 63);
  color: #fff;
  font-family: ${Theme.Fonts.SANS_1_700};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
`;

export default PlaceModal;
