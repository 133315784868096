import React, { useCallback, useEffect, useRef, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { itemViewed } from '../api';
import ChevronForwardIconWhite from '../assets/images/ChevronForwardIconWhite.svg';
import ClockIcon from '../assets/images/ClockIcon.svg';
import CloseIcon from '../assets/images/CloseIcon.svg';
import LocationIcon from '../assets/images/LocationIcon.svg';
import MapPinIconWhite from '../assets/images/MapPinIconWhite.svg';
import PlainSearchIcon from '../assets/images/PlainSearchIcon.svg';
import PlusIcon from '../assets/images/PlusIcon.svg';
import { useActions } from '../hooks/useActions';
import { useSelector } from '../hooks/useSelector';
import { googlePlacesAutocompleteProps, googlePlacesAutocompleteStyles } from '../utils/googlePlacesAutocomplete';
import Theme from '../utils/theme';
import Alert from './Alert';
import NewItemPrompt from './NewItemPrompt';

const Search: React.FC = () => {
  const history = useHistory();

  const {
    searchPlaceById,
    resetLocation,
    getCouncil,
    resetCouncil,
    searchItem,
    setItem,
    getDisposalLocations,
    addItem,
    resetItem,
  } = useActions();

  const { currentLocation, resetSearchLocation } = useSelector(state => state.location);
  const { council } = useSelector(state => state.council);
  const { item, searchItemResults } = useSelector(state => state.item);

  const [searching, setSearching] = useState<boolean>(currentLocation?.formatted_address === undefined);
  const [defaultACValue, setDefaultACValue] = useState<{ label: string; value: string } | undefined>(undefined);
  const [itemSearchTerm, setItemSearchTerm] = useState('');
  const [showNewItemPrompt, setShowNewItemPrompt] = useState(false);

  const selectRef = useRef<any>();

  const selectLocation = (selection: any) => {
    if (selection) {
      Promise.resolve(searchPlaceById(selection.value?.place_id, getCouncil)).then(() => {
        setSearching(false);
      });
    } else {
      clearLocation();
    }
  };

  const selectItem = (itemId: string) => {
    const selectedItem = searchItemResults.find(item => item._id === itemId);
    if (selectedItem) {
      setItem(selectedItem);

      if (council?.id) {
        itemViewed(itemId, council?.id, 'search');
      }

      getDisposalLocations(itemId, council?.id, currentLocation?.geometry.location);

      setItemSearchTerm('');
    }
  };

  const addNewItem = () => {
    addItem(itemSearchTerm, council?.name);
    setShowNewItemPrompt(true);
  };

  const clearLocation = useCallback(() => {
    setDefaultACValue(undefined);
    setSearching(true);

    if (currentLocation?.formatted_address) {
      resetLocation();
      resetCouncil();
    }
  }, [currentLocation, resetLocation, resetCouncil]);

  const clearItem = () => {
    if (item?.name) {
      resetItem();
    }
  };

  const handleSearch = () => {
    if (!currentLocation || !item) {
      alert('Please choose the location and an item');
      return;
    }
    history.push('/results');
  };

  const handlePromptDismiss = useCallback(() => {
    setShowNewItemPrompt(false);
    setItemSearchTerm('');
    resetItem();
  }, [resetItem]);

  useEffect(() => {
    if (itemSearchTerm.length) searchItem(itemSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSearchTerm]);

  useEffect(() => {
    setDefaultACValue(
      currentLocation?.formatted_address !== undefined
        ? {
            label: currentLocation?.formatted_address,
            value: currentLocation?.formatted_address,
          }
        : undefined,
    );
  }, [currentLocation]);

  useEffect(() => {
    if (resetSearchLocation) {
      if (selectRef.current) {
        selectRef?.current.select.select.clearValue();
        selectRef?.current.select.select.focus();
      }
      clearLocation();
    }
  }, [resetSearchLocation, clearLocation]);

  const formatLabel = (props: any) => {
    return (
      <>
        <MapPinIconImg src={MapPinIconWhite} alt="MapPinIcon" className="icon" />
        {props.label}
      </>
    );
  };

  const onInputChange = (value: string) => {
    if (value.length > 0) {
      setSearching(true);
    } else if (currentLocation?.formatted_address === undefined) {
      setSearching(true);
    } else {
      setSearching(false);
    }
  };

  const selectPropOptions = {
    ref: selectRef,
    styles: googlePlacesAutocompleteStyles,
    components: {
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
      NoOptionsMessage: () => null,
      LoadingMessage: () => null,
      clearValue: () => null,
    },
    onChange: selectLocation,
    getOptionLabel: formatLabel,
    onInputChange: onInputChange,
    isClearable: true,
    placeholder: 'Search Location',
    value: defaultACValue,
  };

  return (
    <>
      <SearchContainer>
        <Alert />
        <SearchTitle className="color-white">Learn the right way to recycle</SearchTitle>
        <LocationSearchContainer>
          {searching && <PlainSearchIconImgLocation src={PlainSearchIcon} alt="PlainSearchIcon" className="icon" />}
          <LocationIconImg
            src={currentLocation?.formatted_address ? CloseIcon : LocationIcon}
            alt="LocationIcon"
            className="icon"
            onClick={() => clearLocation()}
          />
          <GooglePlacesAutocomplete selectProps={selectPropOptions} {...googlePlacesAutocompleteProps} />
        </LocationSearchContainer>

        <ItemSearchContainer>
          <PlainSearchIconImg src={PlainSearchIcon} alt="PlainSearchIcon" className="icon" />
          <ClearItemIconImg
            src={item?.name ? CloseIcon : ChevronForwardIconWhite}
            alt="ClearIcon"
            className="icon"
            onClick={() => clearItem()}
          />
          <ItemSearchBox
            placeholder="Search item"
            value={item?.name ? item?.name : itemSearchTerm}
            onChange={e => setItemSearchTerm(e.target.value)}
          />
        </ItemSearchContainer>

        <SearchButton className="btn bg-lightgreen" onClick={() => handleSearch()}>
          Search
        </SearchButton>

        {!!itemSearchTerm && (
          <ItemSearchResults>
            {searchItemResults.map((v, key) => (
              <ResultContainer key={`RC-${key}`}>
                <IconImg src={ClockIcon} alt="" className="icon" />
                <Result onClick={() => selectItem(v._id)}>
                  <ResultText>{v.name}</ResultText>
                </Result>
                <IconImg src={ChevronForwardIconWhite} alt="" className="icon" />
              </ResultContainer>
            ))}
            <AddNewItemContainer onClick={addNewItem}>
              <p className="p-smaller">Item not found? Click to add</p>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <img src={PlusIcon} alt="Add" />
            </AddNewItemContainer>
          </ItemSearchResults>
        )}
      </SearchContainer>
      <NewItemPrompt itemName={itemSearchTerm} show={showNewItemPrompt} onDismiss={handlePromptDismiss} />
    </>
  );
};

const SearchContainer = styled.div`
  width: 35%;
  margin: auto;
  padding: 100px 20px 60px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-top: 0px;
    margin-top: 20px;
  }
`;

const SearchTitle = styled.h1`
  margin-bottom: 30px;
  font-family: ${Theme.Fonts.SANS_1_700};
`;

const LocationSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
  }
`;

const LocationSearchBox = styled.input`
  width: 500px;
  height: 60px;
  border-radius: 40px;
  background: #444a4e;
  border: 0;
  color: #fff;
  padding: 10px 70px;
  outline: none;
  font-size: 1rem;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 336px;
    height: 44px;
    font-size: 0.8rem;
    padding: 10px 50px;
  }
`;

const MapPinIconImg = styled.img`
  position: relative;
  z-index: 1;
  margin-right: 20px;
  top: 6px;
  background-color: #444a4e;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    top: 4px;
    margin-right: 25px;
  }

  @media screen and (max-width: 768px) {
    top: 2px;
    left: 0px;
    margin-right: 15px;
  }
`;

const LocationIconImg = styled.img`
  position: relative;
  top: 45px;
  left: 450px;
  cursor: pointer;
  z-index: 1;

  @media screen and (min-width: 931px) and (max-width: 1024px) {
    top: 43px;
    left: 265px;
  }

  @media screen and (min-width: 769px) and (max-width: 930px) {
    top: 43px;
    left: 227px;
  }

  @media screen and (max-width: 768px) {
    top: 30px;
    left: 300px;
  }
`;

const ItemSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -25px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
  }
`;

const ItemSearchBox = styled(LocationSearchBox)``;

const PlainSearchIconImg = styled.img`
  position: relative;
  top: 70px;
  left: 18px;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    top: 65px;
  }

  @media screen and (max-width: 768px) {
    top: 50px;
    left: 15px;
  }
`;

const PlainSearchIconImgLocation = styled.img`
  position: relative;
  top: 70px;
  left: 20px;
  z-index: 1;
  margin-top: -28px;
  background-color: #444a4e;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    top: 60px;
    margin-top: -22px;
  }

  @media screen and (max-width: 768px) {
    top: 50px;
    left: 15px;
    margin-top: -18px;
  }
`;

const ClearItemIconImg = styled.img`
  position: relative;
  top: 45px;
  left: 450px;
  cursor: pointer;

  @media screen and (min-width: 931px) and (max-width: 1024px) {
    top: 43px;
    left: 265px;
  }

  @media screen and (min-width: 769px) and (max-width: 930px) {
    top: 43px;
    left: 240px;
  }

  @media screen and (max-width: 768px) {
    top: 30px;
    left: 300px;
  }
`;

const SearchButton = styled.button`
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 40px;
  font-family: ${Theme.Fonts.SANS_1_700};
`;

const SearchResults = styled.div`
  width: 500px;
  border-radius: 10px;
  background: #444a4e;
  border: 0;
  color: #fff;
  padding: 0.5rem;
  font-size: 0.8rem;
  z-index: 2;
  position: relative;
  bottom: 160px;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
    bottom: 38px;
  }

  @media screen and (max-width: 768px) {
    width: 336px;
    bottom: 130px;
  }
`;

const IconImg = styled.img``;

const ItemSearchResults = styled(SearchResults)`
  bottom: 80px;
`;

const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  cursor: pointer;
`;

const AddNewItemContainer = styled(ResultContainer)`
  border-top: 1px solid #565a5c;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    margin-bottom: 0px;
  }
`;

const Result = styled.div`
  text-align: left;
  width: 80%;
  padding: 0px 10px;
`;

const ResultText = styled.p`
  margin-bottom: 0px;
  font-size: 0.7rem;
`;

export default Search;
