import { AlertAction } from '../actions';
import { AlertActionType } from '../action-types';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';

export const setAlert = (msg: string, alertType: string) => async (dispatch: Dispatch<AlertAction>) => {
  const id = uuidv4();
  dispatch({
    type: AlertActionType.SET_ALERT,
    payload: {
      msg,
      alertType,
      id,
    },
  });

  setTimeout(() => dispatch({ type: AlertActionType.REMOVE_ALERT, payload: id }), 5000);
};
