import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { itemViewed } from '../api';
import ChevronForwardIcon from '../assets/images/ChevronForwardIconBlack.svg';
import ClockIcon from '../assets/images/ClockIconBlack.svg';
import CloseIcon from '../assets/images/CloseIcon.svg';
import MapPinIcon from '../assets/images/MapPinIconBlack.svg';
import PlainSearchIconDark from '../assets/images/PlainSearchIconDark.svg';
import PlusIcon from '../assets/images/PlusIcon.svg';
import Alert from '../components/Alert';
import BinSystemSelectionModal from '../components/BinSystemSelectionModal';
import CouncilSelectionModal from '../components/CouncilSelectionModal';
import DisposalLocations from '../components/DisposalLocations';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import NewItemPrompt from '../components/NewItemPrompt';
import RecyclingInfo from '../components/RecyclingInfo';
import SearchLocationModal from '../components/SearchLocationModal';
import { useActions } from '../hooks/useActions';
import usePrevious from '../hooks/usePrevious';
import { useSelector } from '../hooks/useSelector';
import { getLink } from '../state/action-creators';

type Params = {
  itemCode?: string;
  linkId?: string;
};

const Results: React.FC = () => {
  const dispatch = useDispatch();

  const { itemCode, linkId } = useParams<Params>();

  const { resetItem, searchItem, setItem, getDisposalLocations, addItem, getItem } = useActions();

  const { currentLocation } = useSelector(state => state.location);
  const { council } = useSelector(state => state.council);
  const { item, searchItemResults } = useSelector(state => state.item);
  const debounceSearch = useRef<any>(null);

  const [itemSearchTerm, setItemSearchTerm] = useState('');
  const [isSearchLocationModalOpen, setIsSearchLocationModalOpen] = useState(false);
  const [showNewItemPrompt, setShowNewItemPrompt] = useState(false);
  const [getLocations, setGetDisposalLocation] = useState(false);

  const previousCouncil = usePrevious(council);

  const selectItem = (itemId: string) => {
    const selectedItem = searchItemResults.find(v => v._id === itemId);
    if (selectedItem) {
      setItem(selectedItem);

      if (council?.id) {
        itemViewed(itemId, council?.id, 'search');
      }

      getDisposalLocations(itemId, council?.id, currentLocation?.geometry.location);
      setItemSearchTerm('');
    }
  };

  const clearItem = () => {
    setItemSearchTerm('');
    resetItem();
  };

  const changeLocation = () => {
    setIsSearchLocationModalOpen(true);
  };

  const addNewItem = () => {
    addItem(itemSearchTerm, council?.name);
    setShowNewItemPrompt(true);
  };

  const handlePromptDismiss = useCallback(() => {
    setShowNewItemPrompt(false);
    setItemSearchTerm('');
    resetItem();
  }, [resetItem]);

  useEffect(() => {
    if (itemCode) {
      getItem(itemCode);
      setGetDisposalLocation(true);
    } else if (linkId) {
      dispatch(getLink(linkId));
      setGetDisposalLocation(true);
    }
    // avoid re-running on getItem redux update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCode]);

  useEffect(() => {
    if (getLocations && item !== null) {
      getDisposalLocations(item?._id, council?.id, currentLocation?.geometry.location);
      setGetDisposalLocation(false);
    }
    // avoid re-running on getDisposalLocations redux update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, getLocations]);

  useEffect(() => {
    if (debounceSearch.current) {
      clearTimeout(debounceSearch.current);
    }

    if (itemSearchTerm.length) {
      debounceSearch.current = setTimeout(() => {
        searchItem(itemSearchTerm);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSearchTerm]);

  useEffect(() => {
    if (council && council?.name !== previousCouncil?.name && item) {
      getDisposalLocations(item._id, council.id, currentLocation?.geometry.location);
    }
  }, [council, item, previousCouncil?.name, currentLocation, getDisposalLocations]);

  return (
    <>
      <header>
        <Navbar isHome={false} />
      </header>
      <MainContainer>
        <ResultsContainer>
          <Alert />
          {itemCode !== undefined && item === null && (
            <>
              <p className="p-smaller color-darkgrey">
                Item could not be found, please use the search bar to find your item
              </p>
              <br />
            </>
          )}

          <Container>
            <SearchBoxLight
              className="p-small"
              placeholder={item ? item.name : 'Search item'}
              value={itemSearchTerm}
              onChange={e => setItemSearchTerm(e.target.value)}
            />
            <SearchIconDarkImg src={PlainSearchIconDark} className="icon" alt="PlainSearchIconDark" />
            <CloseIconImg onClick={() => clearItem()}>
              <img src={CloseIcon} alt="CloseIcon" className="icon" />
            </CloseIconImg>

            {!!itemSearchTerm && (
              <ItemSearchResults>
                {searchItemResults.map((v, key) => (
                  <ResultContainer key={`R-${key}`}>
                    <img src={ClockIcon} alt="" className="icon" />
                    <Result onClick={() => selectItem(v._id)}>
                      <ResultText>{v.name}</ResultText>
                    </Result>
                    <img src={ChevronForwardIcon} alt="" className="icon" />
                  </ResultContainer>
                ))}
                <AddNewItemContainer onClick={addNewItem}>
                  <p className="p-smaller">Item not found? Click to add</p>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <img src={PlusIcon} alt="" className="icon" />
                </AddNewItemContainer>
              </ItemSearchResults>
            )}
          </Container>
          <ItemLocation>
            <p className="p-smaller">
              <img src={MapPinIcon} alt="MapPinIcon" className="map-icon icon" /> &nbsp;&nbsp;{' '}
              {currentLocation?.formatted_address} &nbsp;&nbsp;
              <Link to="#" className="text-link" onClick={changeLocation}>
                Change
              </Link>
            </p>
          </ItemLocation>
          <p className="p-smaller color-darkgrey">{council?.name}</p>
          {item && <RecyclingInfo />}
          {item && <DisposalLocations />}
        </ResultsContainer>
        <SearchLocationModal isModalOpen={isSearchLocationModalOpen} setModalOpen={setIsSearchLocationModalOpen} />
        <CouncilSelectionModal />
        <BinSystemSelectionModal />
      </MainContainer>
      <Footer isHome={false} />
      <NewItemPrompt itemName={itemSearchTerm} show={showNewItemPrompt} onDismiss={handlePromptDismiss} />
    </>
  );
};

const MainContainer = styled.main`
  padding-bottom: 50px;
`;

const ResultsContainer = styled.section`
  width: 600px;
  margin: auto;
  margin-top: 100px;
  text-align: center;

  @media screen and (max-width: 650px) {
    width: 375px;
    margin-top: 50px;
    padding: 0px 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchBoxLight = styled.input`
  width: 500px;
  height: 60px;
  border-radius: 40px;
  border: 0;
  padding: 10px 70px;
  outline: none;
  font-size: 1rem;
  background: rgba(237, 229, 220, 0.6);
  border: 0;
  color: #001929;

  @media screen and (max-width: 768px) {
    height: 44px;
    font-size: 0.8rem;
    padding: 10px 50px;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

const SearchIconDarkImg = styled.img`
  position: relative;
  bottom: 42px;
  right: 220px;

  @media screen and (max-width: 768px) {
    bottom: 33px;
  }

  @media screen and (max-width: 650px) {
    position: relative;
    right: 145px;
    top: -30px;
  }
`;

const CloseIconImg = styled.div`
  position: relative;
  left: 220px;
  bottom: 70px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    bottom: 48px;
  }

  @media screen and (max-width: 650px) {
    position: relative;
    left: 145px;
  }
`;

const SearchResults = styled.div`
  width: 500px;
  border-radius: 10px;
  background: #f6eee7;
  border: 0;
  color: #000;
  padding: 0.5rem;
  font-size: 0.8rem;
  z-index: 2;
  position: relative;
  bottom: 45px;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 83%;
  }

  @media screen and (max-width: 768px) {
    width: 336px;
    bottom: -20px;
  }
`;

const ItemSearchResults = styled(SearchResults)`
  bottom: 30px;
`;

const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  cursor: pointer;
`;

const AddNewItemContainer = styled(ResultContainer)`
  border-top: 1px solid #565a5c;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    margin-bottom: 0px;
  }
`;

const Result = styled.div`
  text-align: left;
  width: 80%;
  padding: 0px 10px;
`;

const ResultText = styled.p`
  margin-bottom: 0px;
  font-size: 0.8rem;
`;

const ItemLocation = styled.div`
  text-align: center;
`;

export default memo(Results);
