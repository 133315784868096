export enum Fonts {
  SANS_1_400 = 'Avenir, sans-serif',
  SANS_1_700 = 'Avenir-Heavy, sans-serif',
  SANS_1_900 = 'Avenir-Black, sans-serif',
  SANS_2_400 = 'Poppins-Regular',
  SANS_2_500 = 'Poppins-Medium',
  SANS_2_600 = 'Poppins-SemiBold',
}

const Theme = {
  Fonts,
};

export default Theme;
