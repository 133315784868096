import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ReactScrollLink } from 'react-scroll';
import styled from 'styled-components';

import AppStoreButton from '../assets/images/AppStoreButton.svg';
import Facebook from '../assets/images/Facebook.svg';
import Flag1 from '../assets/images/Flag1.svg';
import Flag2 from '../assets/images/Flag2.svg';
import GooglePlayButton from '../assets/images/GooglePlayButton.svg';
import GreenArrow from '../assets/images/GreenArrow.svg';
import Instagram from '../assets/images/Instagram.svg';
import Twitter from '../assets/images/Twitter.svg';

import { APPLE_STORE_LINK, FACEBOOK_LINK, GOOGLE_PLAY_LINK, INSTAGRAM_LINK, TWITTER_LINK } from '../utils/constants';

interface FooterProps {
  isHome?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isHome = true }) => {
  return (
    <FooterContainer id="footer">
      <FooterInnerContainer>
        <TopContainer>
          <Contact>
            <h3 className="color-white">Have a question or want to get in touch?</h3>
            <a href={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}>
              <ContactUs>
                <ContactUsText className="color-white">Contact us</ContactUsText>
                <img src={GreenArrow} alt="GreenArrow" />
              </ContactUs>
            </a>
          </Contact>
          <Menu>
            <Nav>
              {isHome ? (
                <Ul>
                  <Li>
                    <ReactScrollLink to="home" smooth={true}>
                      Home
                    </ReactScrollLink>
                  </Li>
                  <Li>
                    <ReactScrollLink to="overview" smooth={true}>
                      Overview
                    </ReactScrollLink>
                  </Li>
                  <Li>
                    <ReactScrollLink to="features" smooth={true}>
                      Features
                    </ReactScrollLink>
                  </Li>
                  <Li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </Li>
                </Ul>
              ) : (
                <Ul>
                  <Li>
                    <Link to="/">Home</Link>
                  </Li>
                  <Li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </Li>
                </Ul>
              )}
            </Nav>
          </Menu>
        </TopContainer>
        <BottomContainer>
          <AppDownloads>
            <DownloadButtonsContainer>
              <a href={APPLE_STORE_LINK} target="_blank" rel="noreferrer">
                <AppDownloadLinkImg src={AppStoreButton} alt="AppStoreButton" />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href={GOOGLE_PLAY_LINK} target="_blank" rel="noreferrer">
                <AppDownloadLinkImg src={GooglePlayButton} alt="GooglePlayButton" />
              </a>
            </DownloadButtonsContainer>
          </AppDownloads>
          <FlagsLinksContainer>
            <FlagsLinks>
              <Flags>
                <img src={Flag1} alt="Flag1" />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <img src={Flag2} alt="Flag2" />
              </Flags>
              <Links>
                <a href={FACEBOOK_LINK} target="_blank" rel="noreferrer">
                  <img src={Facebook} alt="Facebook" />
                </a>
                <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
                  <img src={Instagram} alt="Instagram" />
                </a>
                <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </Links>
            </FlagsLinks>
            <Acknowledgement>
              <p className="p-smaller color-light">Acknowledgement of Country</p>
              <p className="p-smallest color-light">
                We wish to acknowledge the traditional custodians of this land, and their Elders past, present and
                future.
              </p>
            </Acknowledgement>
          </FlagsLinksContainer>
        </BottomContainer>
        <Copyright>
          <p className="p-small color-light">Copyright &copy; RecycleMate. All rights reserved</p>
        </Copyright>
      </FooterInnerContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #283338;
  width: 100%;
`;

const FooterInnerContainer = styled.div`
  max-width: 1440px;
  height: 100%;
  margin: auto;
  padding: 100px;
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    padding: 30px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Contact = styled.div``;

const ContactUs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

const ContactUsText = styled.p`
  margin-bottom: 0px;
  margin-right: 30px;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

const Nav = styled.nav``;

const Ul = styled.ul`
  display: flex;
  align-items: center;
`;

const Li = styled.li`
  margin-left: 50px;

  @media screen and (max-width: 768px) {
    margin-left: 0px;
    margin-right: 50px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const AppDownloads = styled.div`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const DownloadButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 60px;
`;

const AppDownloadLinkImg = styled.img`
  width: 100%;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 85%;
  }
`;

const FlagsLinksContainer = styled.div`
  width: 40%;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const FlagsLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Flags = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Links = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
`;

const Acknowledgement = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 0.9rem;
  }
`;

const Copyright = styled.div``;

export default Footer;
