import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ReactScrollLink } from 'react-scroll';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Logo from '../assets/images/Logo.svg';
import NavbarMenu from './NavbarMenu';
import { ReactComponent as MenuOpenHamburgerIcon } from '../assets/images/menu-open-hamburger.svg';

interface NavbarProps {
  isHome?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isHome = true }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpenClick = () => {
    setIsMenuOpen(true);
  };

  const onMenuRequestClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <Drawer open={isMenuOpen} onClose={onMenuRequestClose} variant={'temporary'} anchor="right">
        <NavbarMenu isHome={isHome} onMenuRequestClose={onMenuRequestClose} />
      </Drawer>
      <NavBarContainer>
        <NavBarInnerContainer>
          <Link to="/">
            <LogoImg src={Logo} alt="Recycle Mate" />
          </Link>
          <Menu>
            <Nav>
              {isHome ? (
                <Ul>
                  <Li>
                    <Link to="/">Home</Link>
                  </Li>
                  <Li>
                    <ReactScrollLink to="overview" smooth={true}>
                      Overview
                    </ReactScrollLink>
                  </Li>
                  <Li>
                    <ReactScrollLink to="features" smooth={true}>
                      Features
                    </ReactScrollLink>
                  </Li>
                  <Li>
                    <Link to={{ pathname: 'https://kb.recyclemate.com.au' }} target="_blank">
                      Knowledge Base
                    </Link>
                  </Li>
                </Ul>
              ) : (
                <Ul>
                  <Li>
                    <Link to="/">Home</Link>
                  </Li>
                </Ul>
              )}
            </Nav>
            <ReactScrollLink to="overview" smooth={true}>
              <DownloadButton className="btn bg-lightgreen">Download app</DownloadButton>
            </ReactScrollLink>
            <MenuOpenHamburgerButton onClick={onMenuOpenClick}>
              <MenuOpenHamburger />
            </MenuOpenHamburgerButton>
          </Menu>
        </NavBarInnerContainer>
      </NavBarContainer>
    </>
  );
};

const NavBarContainer = styled.div`
  width: 100%;
  background-color: #2f393f;
`;

const NavBarInnerContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 90px;

  @media screen and (min-width: 768px) and (max-width: 1240px) {
    padding: 30px 60px;
  }

  @media screen and (max-width: 768px) {
    padding: 30px;
  }
`;

const LogoImg = styled.img`
  width: 178px;
  height: 58px;

  @media screen and (max-width: 768px) {
    width: 135px;
    height: 44px;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Nav = styled.nav`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Ul = styled.ul`
  display: flex;
  align-items: center;
`;

const Li = styled.li`
  margin-left: 50px;

  @media screen and (min-width: 768px) and (max-width: 1240px) {
    margin-left: 30px;
  }
`;

const DownloadButton = styled.button`
  margin-left: 60px;

  @media screen and (min-width: 768px) and (max-width: 1240px) {
    margin-left: 30px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MenuOpenHamburgerButton = styled.button`
  border: none;
  padding: 0;
  margin: 0 20px 0 0;
  background: none;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const MenuOpenHamburger = styled(MenuOpenHamburgerIcon)`
  width: 24px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

export default Navbar;
