import React from 'react';
import { useSelector } from '../hooks/useSelector';

const Alert: React.FC = () => {
  const alerts = useSelector(state => state.alert);
  return (
    <React.Fragment>
      {alerts &&
        alerts.map((alert, index) => (
          <div className="alert" key={index}>
            <p className={'p-smaller ' + alert.alertType}>{alert.msg}</p>
          </div>
        ))}
    </React.Fragment>
  );
};

export default Alert;
