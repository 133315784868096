import a from 'indefinite';
import React, { useCallback } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useSelector } from '../hooks/useSelector';
import Theme from '../utils/theme';

interface NewItemPromptProps {
  itemName: string;
  show?: boolean;
  onDismiss?: () => void;
}
const SupportEmail = process.env.REACT_APP_SUPPORT_EMAIL || 'help@recyclemate.com.au';

const NewItemPrompt: React.FC<NewItemPromptProps> = ({ itemName, show, onDismiss }) => {
  const { council } = useSelector(state => state.council);

  const handleEmailClick = useCallback(() => {
    if (!itemName) {
      return;
    }

    const councilLine = council?.name ? ` I wanted to recycle in ${council.name}.` : '';

    const subject = `Item not found: ${itemName}`;
    const body = `\
Hi Recycle Mate team, \
I tried to find an item in your app, but it was not in your database yet. \
Are you able to let me know how to recycle ${a(itemName.toLowerCase())}?\
${councilLine} \
Thank you\
    `;

    window.location = `mailto:${SupportEmail}?subject=${subject}&body=${body}` as string & Location;

    onDismiss?.();
  }, [council?.name, itemName, onDismiss]);

  return (
    <Modal
      isOpen={!!show}
      className="modal"
      overlayClassName="modal-overlay"
      contentLabel="New Item Prompt"
      onRequestClose={onDismiss}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc>
      <div>
        <ModalTitle>Thank you!</ModalTitle>
        <Body>
          <Description>Whilst I couldn't get you an answer today, your question is helping me learn!</Description>
          <Description>Would you like a member of our team to get back to you?</Description>
        </Body>
        <YesButton className="btn btn-big bg-darkgreen" onClick={handleEmailClick}>
          Yes, please contact me
        </YesButton>
        <NoButton className="btn btn-big bg-darkgreen" onClick={onDismiss}>
          No, thank you
        </NoButton>
      </div>
    </Modal>
  );
};

const ModalTitle = styled.h2`
  line-height: 2.5rem;
  font-family: ${Theme.Fonts.SANS_1_900};

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  flex-direction: column;
`;

const Description = styled.div`
  margin-left: 10px;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const YesButton = styled.button`
  font-family: ${Theme.Fonts.SANS_1_700};
`;

const NoButton = styled.button`
  margin-top: 15px;
  background: #6d6f71;
  font-family: ${Theme.Fonts.SANS_1_700};
`;

export default NewItemPrompt;
