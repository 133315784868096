import { MapPosition } from '../state/types';

export const APPLE_STORE_LINK = 'https://apps.apple.com/au/app/recycle-mate/id1470350559';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.recycle_mate&hl=en_AU';
export const FACEBOOK_LINK = 'https://facebook.com';
export const INSTAGRAM_LINK = 'https://instagram.com';
export const TWITTER_LINK = 'https://twitter.com';
export const SEARCH_DEBOUNCE_MILLISECONDS = 250;

// initial mapbox position
export const INITIAL_MAP_POSITION: MapPosition = {
  lat: -35.36,
  lng: 147.15,
  zoom: 6.0587964,
  zoomWithData: 11,
};

// map bounds for mapbox - Australia
export const INITIAL_MAP_BOUNDS = {
  northWestBounds: { longitude: -26.0, latitude: 141.0 },
  southEastBounds: { longitude: -39.3, latitude: 154.0 },
};

export const IMAGES_ARRAY = [
  { name: 'landfill', url: '../map-landfill.png' },
  { name: '10cBottles', url: '../map-10c.png' },
  { name: 'charityReuse', url: '../map-charity.png' },
  { name: 'commercialRecycler', url: '../map-recycle.png' },
  { name: 'compostHub', url: '../map-compost.png' },
  { name: 'recycleStation', url: '../map-recycle.png' },
  { name: 'wasteandRecyclingFacility', url: '../map-recycle.png' },
  { name: 'no-icon', url: '../map-landfill.png' },
];
