import { FC, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import PlaceholderIcon from '../assets/images/DefaultLocationPreview.svg';
import PlaceholderImage from '../assets/images/location-placeholder.png';
import { Place } from '../types';

export type PlaceImageProps = HTMLAttributes<HTMLDivElement> & {
  place?: Place;
  transparent?: boolean;
};

const PlaceImage: FC<PlaceImageProps> = ({ place, transparent, ...rest }) => {
  const [usePlaceholder, setUsePlaceholder] = useState(false);

  const placeholderSource = useMemo(() => (transparent ? PlaceholderIcon : PlaceholderImage), [transparent]);
  const source = useMemo(
    () => (usePlaceholder ? placeholderSource : `${process.env.REACT_APP_MEDIA_URL}/place-images/${place?.id}.jpg`),
    [place, placeholderSource, usePlaceholder],
  );

  const onError = useCallback(() => {
    setUsePlaceholder(true);
  }, []);

  return (
    <div {...rest}>
      <Image
        src={source}
        onError={onError}
        alt={place?.name ?? 'Disposal location'}
        adjust={usePlaceholder && transparent}
      />
    </div>
  );
};

const Image = styled.img<{ adjust?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: inherit;
  left: ${({ adjust }) => (adjust ? '-10px' : '0')};
`;

export default PlaceImage;
