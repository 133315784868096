import BagTextFoodOrganics from '../assets/images/bins/bag-text-food-organics.png';
import BagTextGarbage from '../assets/images/bins/bag-text-garbage.png';
import BagTextRecycling from '../assets/images/bins/bag-text-recycling.png';
import BinBagBlackLarge from '../assets/images/bins/bin-bag-black-large.png';
import BinBagBlack from '../assets/images/bins/bin-bag-black.png';
import BinBagClearBlackLarge from '../assets/images/bins/bin-bag-clear-black-large.png';
import BinBagClearBlack from '../assets/images/bins/bin-bag-clear-black.png';
import BinBagClearGreenLarge from '../assets/images/bins/bin-bag-clear-green-large.png';
import BinBagClearGreen from '../assets/images/bins/bin-bag-clear-green.png';
import BinBagClearLarge from '../assets/images/bins/bin-bag-clear-large.png';
import BinBagClearRedLarge from '../assets/images/bins/bin-bag-clear-red-large.png';
import BinBagClearRed from '../assets/images/bins/bin-bag-clear-red.png';
import BinBagClear from '../assets/images/bins/bin-bag-clear.png';
import BinBagRedLarge from '../assets/images/bins/bin-bag-red-large.png';
import BinBagRed from '../assets/images/bins/bin-bag-red.png';
import BinBlack from '../assets/images/bins/bin-black.png';
import BinBlue from '../assets/images/bins/bin-blue.png';
import BinCrateNoLid from '../assets/images/bins/bin-crate-no-lid.png';
import BinCrate from '../assets/images/bins/bin-crate.png';
import BinGreen from '../assets/images/bins/bin-green.png';
import BinLime from '../assets/images/bins/bin-lightgreen.png';
import BinMaroon from '../assets/images/bins/bin-maroon.png';
import BinPurple from '../assets/images/bins/bin-purple.png';
import BinRed from '../assets/images/bins/bin-red.png';
import BinYellowSplit from '../assets/images/bins/bin-yellow-split.png';
import BinYellow from '../assets/images/bins/bin-yellow.png';
import LottieBinBlue from '../assets/images/lottie-bins/bin-blue.json';
import LottieBinGrey from '../assets/images/lottie-bins/bin-dark-grey.json';
import LottieBinLime from '../assets/images/lottie-bins/bin-lime-green.json';
import LottieBinMaroon from '../assets/images/lottie-bins/bin-maroon.json';
import LottieBinGreen from '../assets/images/lottie-bins/bin-mid-green.json';
import LottieBinPurple from '../assets/images/lottie-bins/bin-purple.json';
import LottieBinRed from '../assets/images/lottie-bins/bin-red.json';
import LottieBinYellowSplit from '../assets/images/lottie-bins/bin-yellow-split.json';
import LottieBinYellow from '../assets/images/lottie-bins/bin-yellow.json';
import LottieBinCrate from '../assets/images/lottie-bins/black-crate-closed.json';
import LottieBinCrateNoLid from '../assets/images/lottie-bins/black-crate-open.json';
import LottieBinNone from '../assets/images/lottie-bins/no-roadside-collection.json';
import LottieTextGarbage from '../assets/images/lottie-bins/text-bubble-garbage.json';
import LottieTextGarden from '../assets/images/lottie-bins/text-bubble-garden-waste.json';
import LottieTextNone from '../assets/images/lottie-bins/text-bubble-no-kerbside.json';
import LottieTextRecycle from '../assets/images/lottie-bins/text-bubble-recycle.json';
import LottieTextSplitGarbage from '../assets/images/lottie-bins/text-bubble-split-garbage.json';
import LottieTextSplitRecycling from '../assets/images/lottie-bins/text-bubble-split-recycling.json';
import LottieTextFood from '../assets/images/lottie-bins/text-bubble-waste-bin.json';

import { Bin, BinSystem } from '../state/types';
import { Place } from '../types';

export const addressToSuburb = (address: string | undefined) => {
  if (address) {
    const match = address.match(/([A-Z]{1}[a-z| ]+)+(?=(,|\s){1,2}[A-Z]{2,3})/);
    if (match?.length) return match[0];
  }

  return '';
};

export enum WasteType {
  GARBAGE = 'Garbage',
  CARDBOARD = 'Paper and Cardboard',
  CONTAINERS = 'Household Containers',
  GLASS = 'Glass bottles and Jars',
  GARDEN = 'Garden Waste',
  FOOD = 'Food Waste',
  PLA = 'PLA Containers',
  NONE = 'No Kerbside Collection',
}

export enum BinType {
  GARBAGE = 'Garbage',
  RECYCLING = 'Recycling',
  FOOD_AND_GARDEN = 'Food and Garden Waste',
  FOOD = 'Food Waste',
  GARDEN = 'Garden Waste',
  NONE = 'No Kerbside Collection',
}

export enum BinLid {
  RED = 'Red',
  YELLOW = 'Yellow',
  YELLOWSPLIT = 'Yellow Split',
  GREEN = 'Green',
  LIME = 'Lime Green',
  BLUE = 'Blue',
  MAROON = 'Maroon',
  GREY = 'Dark Grey',
  PURPLE = 'Purple',
  CRATE = 'Black Crate no lid',
  CRATE_LID = 'Black Crate with lid',
  BAG_RED = 'Red bag',
  BAG_CLEAR = 'Clear',
  BAG_BLACK = 'BYO',
  BAG_CLEAR_BLACK = 'Clear/Black',
  BAG_CLEAR_GREEN = 'Clear/Green',
  BAG_CLEAR_RED = 'Clear/Red',
  NONE = 'No Bin System',
}

export const binForWasteType = (wasteType: string | undefined, binSystem: BinSystem) => {
  switch (wasteType) {
    case WasteType.CARDBOARD:
      return binSystem.find(v => v.acceptsCardboard);
    case WasteType.CONTAINERS:
      return binSystem.find(v => v.acceptsContainers);
    case WasteType.FOOD:
      return binSystem.find(v => v.acceptsFood);
    case WasteType.GARBAGE:
      return binSystem.find(v => v.acceptsGarbage);
    case WasteType.GARDEN:
      return binSystem.find(v => v.acceptsGarden);
    case WasteType.GLASS:
      return binSystem.find(v => v.acceptsGlass);
    case WasteType.NONE:
      return binSystem.find(v => v.wasteType === WasteType.NONE);
    default:
      return undefined;
  }
};

/**
 * Flag for handling bag bin systems differently.
 */
export const isBag = (bin?: Bin) =>
  !!bin?.binType &&
  [
    BinLid.BAG_RED,
    BinLid.BAG_BLACK,
    BinLid.BAG_CLEAR,
    BinLid.BAG_CLEAR_RED,
    BinLid.BAG_CLEAR_GREEN,
    BinLid.BAG_CLEAR_BLACK,
  ].includes(bin.binType as BinLid);

export const lottieForBin = (bin?: Bin) => {
  let lottieBin: any = LottieBinNone;
  let lottieText: any = LottieTextNone;

  if (bin === undefined) {
    return {
      lottieBin: LottieBinNone,
      lottieText: LottieTextNone,
    };
  }

  switch (bin.binType) {
    case BinLid.BLUE:
      lottieBin = LottieBinBlue;
      break;
    case BinLid.CRATE:
      lottieBin = LottieBinCrateNoLid;
      break;
    case BinLid.CRATE_LID:
      lottieBin = LottieBinCrate;
      break;
    case BinLid.GREEN:
      lottieBin = LottieBinGreen;
      break;
    case BinLid.GREY:
      lottieBin = LottieBinGrey;
      break;
    case BinLid.LIME:
      lottieBin = LottieBinLime;
      break;
    case BinLid.MAROON:
      lottieBin = LottieBinMaroon;
      break;
    case BinLid.PURPLE:
      lottieBin = LottieBinPurple;
      break;
    case BinLid.RED:
      lottieBin = LottieBinRed;
      break;
    case BinLid.YELLOW:
      lottieBin = LottieBinYellow;
      break;
    case BinLid.YELLOWSPLIT:
      lottieBin = LottieBinYellowSplit;
  }

  switch (bin.wasteType) {
    case BinType.FOOD:
    case BinType.FOOD_AND_GARDEN:
      lottieText = LottieTextFood;
      break;
    case BinType.GARBAGE:
      lottieText = bin.binType === BinLid.YELLOWSPLIT ? LottieTextSplitGarbage : LottieTextGarbage;
      break;
    case BinType.GARDEN:
      lottieText = LottieTextGarden;
      break;
    case BinType.RECYCLING:
      lottieText = bin.binType === BinLid.YELLOWSPLIT ? LottieTextSplitRecycling : LottieTextRecycle;
      break;
  }

  return { lottieBin, lottieText };
};

export const imageForBin = (bin?: Bin) => {
  let binImage = BinBlack;

  if (bin) {
    switch (bin.binType) {
      case BinLid.BLUE:
        binImage = BinBlue;
        break;
      case BinLid.CRATE:
        binImage = BinCrateNoLid;
        break;
      case BinLid.CRATE_LID:
        binImage = BinCrate;
        break;
      case BinLid.GREEN:
        binImage = BinGreen;
        break;
      case BinLid.GREY:
        binImage = BinBlack;
        break;
      case BinLid.LIME:
        binImage = BinLime;
        break;
      case BinLid.MAROON:
        binImage = BinMaroon;
        break;
      case BinLid.PURPLE:
        binImage = BinPurple;
        break;
      case BinLid.RED:
        binImage = BinRed;
        break;
      case BinLid.YELLOW:
        binImage = BinYellow;
        break;
      case BinLid.YELLOWSPLIT:
        binImage = BinYellowSplit;
        break;
      case BinLid.BAG_RED:
        binImage = BinBagRed;
        break;
      case BinLid.BAG_BLACK:
        binImage = BinBagBlack;
        break;
      case BinLid.BAG_CLEAR:
        binImage = BinBagClear;
        break;
      case BinLid.BAG_CLEAR_RED:
        binImage = BinBagClearRed;
        break;
      case BinLid.BAG_CLEAR_GREEN:
        binImage = BinBagClearGreen;
        break;
      case BinLid.BAG_CLEAR_BLACK:
        binImage = BinBagClearBlack;
        break;
    }
  }

  return binImage;
};

/**
 * Returns the image and text for a bag bin.
 */
export const bagImageForBin = (bin?: Bin) => {
  let bagImage;
  let bagText;

  switch (bin?.binType) {
    case BinLid.BAG_RED:
      bagImage = BinBagRedLarge;
      break;
    case BinLid.BAG_BLACK:
      bagImage = BinBagBlackLarge;
      break;
    case BinLid.BAG_CLEAR:
      bagImage = BinBagClearLarge;
      break;
    case BinLid.BAG_CLEAR_RED:
      bagImage = BinBagClearRedLarge;
      break;
    case BinLid.BAG_CLEAR_GREEN:
      bagImage = BinBagClearGreenLarge;
      break;
    case BinLid.BAG_CLEAR_BLACK:
      bagImage = BinBagClearBlackLarge;
      break;
    default:
      bagImage = undefined;
  }

  switch (bin?.wasteType) {
    case BinType.FOOD:
      bagText = BagTextFoodOrganics;
      break;
    case BinType.FOOD_AND_GARDEN:
      bagText = BagTextFoodOrganics;
      break;
    case BinType.GARBAGE:
      bagText = BagTextGarbage;
      break;
    case BinType.GARDEN:
      bagText = BagTextFoodOrganics;
      break;
    case BinType.RECYCLING:
      bagText = BagTextRecycling;
      break;
    default:
      bagText = undefined;
  }

  return { bagImage, bagText };
};

/**
 * Returns text description for a bin.
 */
export const textDescriptionForBin = (binType?: BinType) => {
  switch (binType) {
    case BinType.FOOD:
      return 'Food organics';
    case BinType.FOOD_AND_GARDEN:
      return 'Food & Garden organics';
    case BinType.GARBAGE:
      return 'General waste';
    case BinType.GARDEN:
      return 'Garden organics';
    case BinType.RECYCLING:
      return 'Recycling';
    default:
      return 'General';
  }
};

/**
 * Returns a number for how many recycling types a bin accepts.
 */
const binRecyclingRank = (bin: Bin) =>
  [bin.acceptsCardboard, bin.acceptsContainers, bin.acceptsGlass].filter(v => v).length;

/**
 * Custom sort function for bins.
 */
export const sortBins = (bins: Bin[]) => {
  // sorting order
  const sortBy = [
    BinType.GARBAGE.valueOf(),
    BinType.RECYCLING.valueOf(),
    BinType.GARDEN.valueOf(),
    BinType.FOOD.valueOf(),
    BinType.FOOD_AND_GARDEN.valueOf(),
    BinType.NONE.valueOf(),
  ];

  // custom sorting based on waste type
  return bins.slice().sort((a, b) => {
    const aIndex = sortBy.indexOf(a.wasteType);
    const bIndex = sortBy.indexOf(b.wasteType);

    const diff = aIndex - bIndex;

    // If waste types are both `recycling`, do a secondary sort by how general it is (how many recycling types it accepts).
    if (!diff && a.wasteType === BinType.RECYCLING) {
      const aRank = binRecyclingRank(a);
      const bRank = binRecyclingRank(b);

      return bRank - aRank;
    }

    return diff;
  });
};

export const haversineDistance = (point1: [number, number], point2: [number, number]) => {
  const R = 6371000; // Earth radius in meters
  const dLat = ((point2[1] - point1[1]) * Math.PI) / 180;
  const dLon = ((point2[0] - point1[0]) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((point1[1] * Math.PI) / 180) *
      Math.cos((point2[1] * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

/**
 * gets the search query parameters in a key value pairs
 */
export function getQuery() {
  let url = window.location.search;
  var query = url.substring(1);
  var result: any = {};
  query.split('&').forEach(function (part) {
    var item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

// pass json string of place data
export function alertParentFromIframe(message: any) {
  window.parent.postMessage({ message: message }, '*');
}

/**
 * Ensures that no two places are closer than `minDistance` meters apart. Applies a small random offset so that places
 * with identical coordinates are clustered around a point instead of spread in a straight line.
 */
export const adjustPlaceCoordinates = (places: Place[], minDistance: number) => {
  const R = 6371000; // Earth radius in meters
  const adjustedPlaces: Place[] = [];

  places.forEach(place => {
    const adjustedPlace = { ...place };

    for (const otherPlace of adjustedPlaces) {
      const distance = haversineDistance(adjustedPlace.location.coordinates, otherPlace.location.coordinates);

      if (distance < minDistance) {
        const angle = Math.atan2(
          adjustedPlace.location.coordinates[0] - otherPlace.location.coordinates[0] + (Math.random() - 0.5) / 100,
          adjustedPlace.location.coordinates[1] - otherPlace.location.coordinates[1] + (Math.random() - 0.5) / 100,
        );

        adjustedPlace.location.coordinates = [
          otherPlace.location.coordinates[0] +
            (((minDistance * Math.sin(angle)) / R) * (180 / Math.PI)) /
              Math.cos(otherPlace.location.coordinates[1] * (Math.PI / 180)),
          otherPlace.location.coordinates[1] + ((minDistance * Math.cos(angle)) / R) * (180 / Math.PI),
        ];
      }
    }

    adjustedPlaces.push(adjustedPlace);
  });

  return adjustedPlaces;
};
