export enum LocationActionType {
  SEARCH_LOCATION_PENDING = 'search_location_pending',
  SEARCH_LOCATION_SUCCESS = 'search_location_success',
  SEARCH_LOCATION_ERROR = 'search_location_error',
  SET_CURRENT_LOCATION = 'set_current_location',
  SET_CURRENT_LOCATION_POSTCODE = 'set_current_location_postcode',
  RESET_LOCATION = 'reset_location',
  RESET_SEARCH_LOCATION = 'reset_search_location',
}

export enum CouncilActionType {
  GET_COUNCIL_PENDING = 'get_council_pending',
  SET_COUNCIL = 'set_council',
  SET_COUNCIL_CANDIDATES = 'set_council_candidates',
  GET_COUNCIL_ERROR = 'get_council_error',
  SET_BIN_SYSTEM = 'set_bin_system',
  SET_BIN_SYSTEM_CANDIDATES = 'set_bin_system_candidates',
  RESET_COUNCIL = 'reset_council',
}

export enum ItemActionType {
  SEARCH_ITEM_PENDING = 'search_item_pending',
  SET_ITEM_RESULTS = 'set_item_results',
  SEARCH_ITEM_ERROR = 'search_item_error',
  SET_ITEM = 'set_item',
  SET_ITEM_WASTE_TYPE = 'set_item_waste_type',
  RESET_ITEM = 'reset_item',
  GET_DISPOSAL_LOCATIONS_PENDING = 'get_disposal_locations_pending',
  GET_DISPOSAL_LOCATIONS_FOR_COUNCIL_PENDING = 'get_disposal_locations_for_council_pending',
  SET_DISPOSAL_LOCATIONS = 'set_disposal_locations',
  SET_DISPOSAL_LOCATIONS_FOR_COUNCIL = 'set_disposal_locations_for_council',
  ADD_DISPOSAL_LOCATION = 'add_disposal_location',
  GET_DISPOSAL_LOCATIONS_ERROR = 'get_disposal_locations_error',
  GET_DISPOSAL_LOCATIONS_FOR_COUNCIL_ERROR = 'get_disposal_locations_for_council_error',
  FINISH_LOADING = 'finish_loading',
  FINISH_LOADING_FOR_COUNCIL = 'finish_loading_for_council',
  SET_DISPOSAL_LOCATION_TO_VIEW = 'set_disposal_location_to_view',
  RESET_DISPOSAL_LOCATION_TO_VIEW = 'reset_disposal_location_to_view',
  GET_DISPOSAL_LOCATION_PHOTO_PENDING = 'get_disposal_location_photo_pending',
  SET_DISPOSAL_LOCATION_PHOTO = 'set_disposal_location_photo',
  GET_ITEM_PENDING = 'get_item_pending',
  GET_ITEM_ERROR = 'get_item_error',
}

export enum AlertActionType {
  SET_ALERT = 'set_alert',
  REMOVE_ALERT = 'remove_alert',
}
