import { LocationAction } from '../actions';
import { LocationActionType } from '../action-types';
import { Location } from '../types';
interface LocationState {
  loading: boolean;
  searchLocationResults: Location[];
  currentLocation: Location | null;
  currentLocationPostCode: string | null;
  error: string | null;
  resetSearchLocation: boolean;
}

const initialState = {
  loading: false,
  searchLocationResults: [],
  currentLocation: null,
  currentLocationPostCode: null,
  error: null,
  resetSearchLocation: false,
};

const reducer = (state: LocationState = initialState, action: LocationAction): LocationState => {
  switch (action.type) {
    case LocationActionType.SEARCH_LOCATION_PENDING:
      return {
        ...state,
        loading: true,
      };
    case LocationActionType.SEARCH_LOCATION_SUCCESS:
      return {
        ...state,
        searchLocationResults: action.payload,
        loading: false,
      };
    case LocationActionType.SEARCH_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LocationActionType.SET_CURRENT_LOCATION:
      return {
        ...state,
        searchLocationResults: [],
        currentLocation: action.payload,
      };
    case LocationActionType.SET_CURRENT_LOCATION_POSTCODE:
      return {
        ...state,
        currentLocationPostCode: action.payload,
      };
    case LocationActionType.RESET_LOCATION:
      return initialState;
    case LocationActionType.RESET_SEARCH_LOCATION:
      return {
        ...state,
        resetSearchLocation: true,
      };
    default:
      return state;
  }
};

export default reducer;
