import React from 'react';
import styled from 'styled-components';

import Phones from '../assets/images/Phones.png';
import SearchIcon from '../assets/images/SearchIcon.svg';
import BinIcon from '../assets/images/BinIcon.svg';
import LightIcon from '../assets/images/LightIcon.svg';
import CpuIcon from '../assets/images/CpuIcon.svg';
import Theme from '../utils/theme';

const Features: React.FC = () => {
  return (
    <FeaturesContainer id="features">
      <FeaturesInnerContainer>
        <FeatureList>
          <Feature>
            <FeatureIconContainer className="style1">
              <img src={CpuIcon} alt="CpuIcon" />
            </FeatureIconContainer>
            <FeatureText>
              <h4 className="color-darkest">Artificial Intelligence</h4>
              <p className="p-small color-dark">
                Use our leading intelligence to identify your waste and recyclables to find the best local disposal
                option.
              </p>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureIconContainer className="style2">
              <img src={SearchIcon} alt="SearchIcon" />
            </FeatureIconContainer>
            <FeatureText>
              <h4 className="color-darkest">Advanced Search</h4>
              <p className="p-small color-dark">
                Search from over 4,000 items in our comprehensive directory. This is continually growing with community
                feedback.
              </p>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureIconContainer className="style1">
              <img src={BinIcon} alt="BinIcon" />
            </FeatureIconContainer>
            <FeatureText>
              <h4 className="color-darkest">Disposal solutions</h4>
              <p className="p-small color-dark">
                Confirm which bin to use at home or learn if there is a better recycling option near you.
              </p>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureIconContainer className="style2">
              <img src={LightIcon} alt="LightIcon" />
            </FeatureIconContainer>
            <FeatureText>
              <h4 className="color-darkest">Recycle tips</h4>
              <p className="p-small color-dark">
                Receive recycling information and instructions relevant to specific items and your local area.
              </p>
            </FeatureText>
          </Feature>
        </FeatureList>
        <MobileImageContainer>
          <img src={Phones} className="mobile-image full-width" alt="Phones" />
        </MobileImageContainer>
      </FeaturesInnerContainer>
    </FeaturesContainer>
  );
};

const FeaturesContainer = styled.section`
  width: 100%;
`;

const FeaturesInnerContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 20px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 30px 20px;
    padding: 0px;
  }
`;

const FeatureList = styled.div`
  width: 50%;
  padding: 50px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`;

const Feature = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureIconContainer = styled.div`
  width: 30%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeatureText = styled.div`
  width: 70%;
  text-align: left;

  h4 {
    font-family: ${Theme.Fonts.SANS_1_700};
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    padding-left: 0px;
  }
`;

const MobileImageContainer = styled.div`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export default Features;
