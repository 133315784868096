import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useActions } from '../hooks/useActions';
import { useSelector } from '../hooks/useSelector';
import { addressToSuburb } from '../utils/helpers';

import MapPinIcon from '../assets/images/MapPinIcon.svg';
import Theme from '../utils/theme';

const CouncilSelectionModal: React.FC = () => {
  const { setCouncil, setResetSearchLocation } = useActions();
  const { currentLocation } = useSelector(state => state.location);
  const { council, councilCandidates } = useSelector(state => state.council);

  const [isCouncilSelectionModalOpen, setIsCouncilSelectionModalOpen] = useState(false);
  const [selectedCouncilName, setSelectedCouncilName] = useState('');

  const handleCouncilSelection = () => {
    const selectedCouncil = councilCandidates.find(candidate => candidate.name === selectedCouncilName);
    if (selectedCouncil) {
      if (!selectedCouncil?.id) {
        setResetSearchLocation();
        setIsCouncilSelectionModalOpen(false);
        return;
      }
      setCouncil(selectedCouncil);
    }
  };

  useEffect(() => {
    if (!council && councilCandidates.length > 0) {
      setIsCouncilSelectionModalOpen(true);
    }
  }, [council, councilCandidates]);

  useEffect(() => {
    if (council) {
      setIsCouncilSelectionModalOpen(false);
    }
  }, [council]);

  return (
    <Modal
      isOpen={isCouncilSelectionModalOpen}
      className="modal"
      overlayClassName="modal-overlay"
      contentLabel="Council Selection Modal"
      appElement={document.getElementById('root') as HTMLElement}>
      <div>
        <ModalTitle>Which council do you belong to?</ModalTitle>
        <Location>
          <img src={MapPinIcon} alt="MapPinIcon" className="icon" />
          <LocationText>
            We have you in <span className="color-green">{addressToSuburb(currentLocation?.formatted_address)}</span>
          </LocationText>
        </Location>
        <Selection>
          {councilCandidates.map(councilCandidate => (
            <SelectionButton
              key={councilCandidate.name}
              className={
                selectedCouncilName === councilCandidate.name
                  ? 'btn btn-select color-black btn-chosen'
                  : 'btn btn-select color-black'
              }
              onClick={() => setSelectedCouncilName(councilCandidate.name)}>
              {councilCandidate.name}
            </SelectionButton>
          ))}
        </Selection>
        <ConfirmButton
          className="btn btn-big bg-darkgreen"
          onClick={handleCouncilSelection}
          disabled={selectedCouncilName === ''}>
          Confirm
        </ConfirmButton>
      </div>
    </Modal>
  );
};

const ModalTitle = styled.h2`
  margin-bottom: 20px;
  line-height: 2.5rem;
  font-family: ${Theme.Fonts.SANS_1_700};

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const LocationText = styled.div`
  margin-left: 10px;
  font-family: ${Theme.Fonts.SANS_1_700};

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const Selection = styled.div`
  margin-bottom: 20px;
`;

const SelectionButton = styled.button`
  width: 100%;
  margin-bottom: 20px;
  font-family: ${Theme.Fonts.SANS_2_600};
`;

const ConfirmButton = styled.button`
  font-family: ${Theme.Fonts.SANS_1_700};
`;

export default CouncilSelectionModal;
