import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useActions } from '../hooks/useActions';
import { useSelector } from '../hooks/useSelector';

import LocationIconBlack from '../assets/images/LocationIconBlack.svg';
import MapPinIconBlack from '../assets/images/MapPinIconBlack.svg';
import PlainSearchIconBlack from '../assets/images/PlainSearchIconBlack.svg';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { googlePlacesAutocompleteProps } from '../utils/googlePlacesAutocomplete';
import Theme from '../utils/theme';

export interface SearchLocationModalProps {
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchLocationModal: React.FC<SearchLocationModalProps> = ({ isModalOpen, setModalOpen }) => {
  const { getCouncil, searchPlaceById } = useActions();
  const { currentLocation } = useSelector(state => state.location);
  const [defaultACValue, setDefaultACValue] = useState<{ label: string; value: string } | undefined>(undefined);

  const selectLocation = (selection: any) => {
    if (selection) {
      Promise.resolve(searchPlaceById(selection.value.place_id, getCouncil)).then(() => {
        setModalOpen(false);
      });
    } else {
      clearLocation();
    }
  };

  const clearLocation = () => {
    setDefaultACValue(undefined);
  };

  useEffect(() => {
    if (!currentLocation) {
      setModalOpen(true);
    }
  }, [setModalOpen, currentLocation]);

  const formatLabel = (props: any) => {
    return (
      <>
        <MapPinIconImg src={MapPinIconBlack} alt="MapPinIcon" className="icon" />
        {props.label}
      </>
    );
  };

  const selectPropOptions = {
    styles: googlePlacesAutocompleteStyles,
    components: {
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
      NoOptionsMessage: () => null,
      LoadingMessage: () => null,
      clearValue: () => null,
    },
    onChange: selectLocation,
    getOptionLabel: formatLabel,
    closeMenuOnScroll: false,
    menuIsOpen: true,
    isClearable: true,
    placeholder: 'Search Location',
    value: defaultACValue,
  };

  const closeModal = () => {
    if (currentLocation) {
      setModalOpen(false);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className="modal"
      overlayClassName="modal-overlay"
      contentLabel="Search Location Modal"
      ariaHideApp={false}>
      <MainContainer>
        <ModalTitle>Looks like we need your address</ModalTitle>
        <PlainSearchIconImg src={PlainSearchIconBlack} alt="PlainSearchIcon" className="icon" />
        <LocationIconImg src={LocationIconBlack} alt="LocationIcon" className="icon" />
        <GooglePlacesAutocomplete selectProps={selectPropOptions} {...googlePlacesAutocompleteProps} />
      </MainContainer>
    </Modal>
  );
};

const googlePlacesAutocompleteStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    left: '10px',
    top: '-30px',
  }),
  option: () => ({
    borderBottom: '1px #fff solid',
    color: '#000',
    backgroundColor: '#fff',
    cursor: 'pointer',
    border: '1px solid #c1c1c1',
    borderRadius: '10px',
    padding: '12px 50px 10px 10px',
    margin: '0 0 10px 0',
    alignItems: 'left',
    textAlign: 'left',
    ':active': {
      backgroundColor: '#fafafa',
    },
    height: '70px',
    '@media screen and (max-width: 768px)': {
      padding: '20px 20px 10px 20px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '0.8rem',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      padding: '16px 50px 10px 20px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '0.8rem',
    },
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'left',
    backgroundColor: '#fff',
    border: '1px solid #c1c1c1',
    padding: '0 50px 0 10px',
    color: '#000',
    borderRadius: '40px',
    width: '500px',
    height: '60px',
    outline: 'none',
    fontSize: '1rem',
    '@media screen and (max-width: 768px)': {
      width: '300px',
      height: '44px',
      fontSize: '0.8rem',
      padding: '10px 5px 10px 5px',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      width: '100%',
    },
  }),
  input: () => ({
    color: '#000',
    padding: '0px 0 0 50px',
    '@media screen and (max-width: 768px)': {
      padding: '0px 0px 0px 35px',
      top: '15px',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      padding: '0px 0 0 50px',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    top: '28px',
    padding: '5px 0 0 50px',
    '@media screen and (max-width: 768px)': {
      top: '12px',
      padding: '0px 0 0 35px',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      padding: '0px 0 0 50px',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '40px',
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: 'none',
    height: 'auto',
    position: 'relative',
    width: '500px',
    '@media screen and (max-width: 768px)': {
      width: '300px',
    },
  }),
  menuList: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    backgroundColor: '#fff',
    color: '#000',
    padding: '0',
    border: '0',
    boxShadow: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '0.8rem',
    maxHeight: '310px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    transition: 'opacity 300ms',
    color: '#000',
    top: '45%',
    '@media screen and (max-width: 768px)': {
      top: '14px',
      left: '10px',
      maxWidth: 'calc(100% - 40px)',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      top: '30px',
    },
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    position: 'absolute',
    zIndex: '10',
    color: '#000',
    right: '15px',
    cursor: 'pointer',
    fill: 'green',
    width: '50px',
    ' > svg': {
      fill: 'transparent',
    },
  }),
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.h2`
  font-family: ${Theme.Fonts.SANS_1_700};
  margin-bottom: 20px;
  line-height: 2.5rem;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const PlainSearchIconImg = styled.img`
  position: relative;
  right: 214px;
  top: 41px;
  z-index: 10;
  background-color: #fff;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    right: 275px;
    top: 34px;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    right: 130px;
    top: 20px;
  }
`;

const LocationIconImg = styled.img`
  position: relative;
  left: 214px;
  top: 15px;
  z-index: 10;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    left: 290px;
    top: 15px;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    left: 115px;
    top: 3px;
  }
`;

const MapPinIconImg = styled.img`
  position: relative;
  z-index: 1;
  margin-right: 20px;
  top: 6px;
  background-color: #fff;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    top: 4px;
    margin-right: 25px;
  }

  @media screen and (max-width: 768px) {
    top: 4px;
    left: 0px;
    margin-right: 15px;
  }
`;

export default SearchLocationModal;
