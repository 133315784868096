import { CouncilActionType } from '../action-types';
import { CouncilAction } from '../actions';
import { BinSystem, BinSystemCandidates, Council, CouncilCandidates } from '../types';

interface CouncilState {
  loading: boolean;
  councilCandidates: CouncilCandidates;
  council: Council | null;
  binSystemCandidates: BinSystemCandidates;
  binSystem: BinSystem;
  error: string | null;
}

const initialState = {
  loading: false,
  councilCandidates: [],
  council: null,
  binSystemCandidates: [],
  binSystem: [],
  error: null,
};

const reducer = (state: CouncilState = initialState, action: CouncilAction): CouncilState => {
  switch (action.type) {
    case CouncilActionType.GET_COUNCIL_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CouncilActionType.SET_COUNCIL:
      return {
        ...state,
        council: action.payload,
        councilCandidates: [],
        binSystem: [],
        loading: false,
      };
    case CouncilActionType.SET_COUNCIL_CANDIDATES:
      return {
        ...state,
        council: null,
        councilCandidates: action.payload,
        loading: false,
      };
    case CouncilActionType.GET_COUNCIL_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CouncilActionType.SET_BIN_SYSTEM:
      return {
        ...state,
        binSystem: action.payload,
        binSystemCandidates: [],
        loading: false,
      };
    case CouncilActionType.SET_BIN_SYSTEM_CANDIDATES:
      return {
        ...state,
        binSystemCandidates: action.payload,
        loading: false,
      };
    case CouncilActionType.RESET_COUNCIL:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
