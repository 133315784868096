import { combineReducers } from 'redux';
import locationReducer from './locationReducer';
import councilReducer from './councilReducer';
import itemReducer from './itemReducer';
import alertReducer from './alertReducer';

const rootReducer = combineReducers({
  location: locationReducer,
  council: councilReducer,
  item: itemReducer,
  alert: alertReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
