import { ItemActionType } from '../action-types';
import { ItemAction } from '../actions';
import { DisposalLocation, Item } from '../types';

interface ItemState {
  loading: boolean;
  searchItemResults: Item[];
  item: Item | null;
  error: string | null;
  disposalLocations: DisposalLocation[];
  disposalLocationsForCouncil: {
    destinations: DisposalLocation[];
    initialMapPosition: [number, number] | null;
  };
  disposalLocationToView: DisposalLocation | null;
  disposalLocationImage: string | null;
}

const initialState = {
  loading: false,
  searchItemResults: [],
  item: null,
  error: null,
  disposalLocations: [],
  disposalLocationsForCouncil: {
    destinations: [],
    initialMapPosition: null,
  },
  disposalLocationToView: null,
  disposalLocationImage: null,
};

const reducer = (state: ItemState = initialState, action: ItemAction): ItemState => {
  switch (action.type) {
    case ItemActionType.SEARCH_ITEM_PENDING:
    case ItemActionType.GET_ITEM_PENDING:
      return {
        ...state,
        loading: true,
      };
    case ItemActionType.SET_ITEM_RESULTS:
      return {
        ...state,
        searchItemResults: action.payload,
        loading: false,
      };
    case ItemActionType.SEARCH_ITEM_ERROR:
    case ItemActionType.GET_ITEM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ItemActionType.SET_ITEM:
      return {
        ...state,
        searchItemResults: [],
        item: action.payload,
      };
    case ItemActionType.RESET_ITEM:
      return initialState;
    case ItemActionType.FINISH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ItemActionType.GET_DISPOSAL_LOCATIONS_PENDING:
      return {
        ...state,
        disposalLocations: [],
        loading: true,
      };
    case ItemActionType.GET_DISPOSAL_LOCATIONS_FOR_COUNCIL_PENDING:
      return {
        ...state,
        disposalLocationsForCouncil: {
          destinations: [],
          initialMapPosition: null,
        },
        loading: true,
      };
    case ItemActionType.SET_DISPOSAL_LOCATIONS:
      return {
        ...state,
        loading: false,
        disposalLocations: action.payload.destinations,
        item: {
          ...(state.item ?? {}),
          wasteType: (action.payload.wasteType || state.item?.wasteType) ?? '',
        } as Item | null,
      };
    case ItemActionType.SET_DISPOSAL_LOCATIONS_FOR_COUNCIL:
      return {
        ...state,
        loading: false,
        disposalLocationsForCouncil: action.payload,
      };
    case ItemActionType.ADD_DISPOSAL_LOCATION:
      return {
        ...state,
        disposalLocations: [...state.disposalLocations, action.payload],
      };
    case ItemActionType.GET_DISPOSAL_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ItemActionType.GET_DISPOSAL_LOCATIONS_FOR_COUNCIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ItemActionType.SET_DISPOSAL_LOCATION_TO_VIEW:
      return {
        ...state,
        disposalLocationToView: action.payload,
      };
    case ItemActionType.RESET_DISPOSAL_LOCATION_TO_VIEW:
      return {
        ...state,
        disposalLocationToView: null,
        disposalLocationImage: null,
      };
    case ItemActionType.GET_DISPOSAL_LOCATION_PHOTO_PENDING:
      return {
        ...state,
        loading: true,
      };
    case ItemActionType.SET_DISPOSAL_LOCATION_PHOTO:
      return {
        ...state,
        disposalLocationImage: action.payload,
        loading: false,
      };
    case ItemActionType.SET_ITEM_WASTE_TYPE:
      return state.item && action.payload
        ? {
            ...state,
            item: {
              ...state.item,
              wasteType: action.payload,
            },
          }
        : state;
    default:
      return state;
  }
};

export default reducer;
