import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import ModalCloseIcon from '../assets/images/ModalCloseIcon.svg';
import { useActions } from '../hooks/useActions';
import { useSelector } from '../hooks/useSelector';
import { Place } from '../types';
import Theme from '../utils/theme';
import _PlaceImage from './PlaceImage';

const DisposalLocationDetailsModal: React.FC = () => {
  const { resetDisposalLocationToView } = useActions();
  const { loading, disposalLocationToView } = useSelector(state => state.item);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (disposalLocationToView) setIsModalOpen(true);
  }, [disposalLocationToView]);

  const closeModal = () => {
    resetDisposalLocationToView();
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className="modal modal-recycle"
      overlayClassName="modal-overlay"
      contentLabel="Disposal Location"
      ariaHideApp={false}>
      {loading && (
        <LoaderContainer>
          <ClipLoader loading={loading} />
        </LoaderContainer>
      )}
      {!loading && (
        <div>
          <CloseButton src={ModalCloseIcon} onClick={closeModal} />
          <PlaceImage place={disposalLocationToView as Place} />
          <CentreDetails>
            <LocationName>{disposalLocationToView?.name}</LocationName>
            <ContactHours>
              <Contact>
                {disposalLocationToView?.address && (
                  <ContactMode>
                    <SectionTitle className="p-smaller">Address</SectionTitle>
                    <a
                      href={'https://www.google.com/maps/place/' + disposalLocationToView?.address}
                      className="text-link"
                      target="_blank"
                      rel="noreferrer">
                      <p className="p-smallest">{disposalLocationToView?.address}</p>
                    </a>
                  </ContactMode>
                )}
                {disposalLocationToView?.phone && (
                  <ContactMode>
                    <SectionTitle className="p-smaller">Phone</SectionTitle>
                    <a href={'tel:' + disposalLocationToView?.phone} className="text-link">
                      <p className="p-smallest">{disposalLocationToView?.phone}</p>
                    </a>
                  </ContactMode>
                )}
                {disposalLocationToView?.website && (
                  <ContactMode>
                    <SectionTitle className="p-smaller">Website</SectionTitle>
                    <a href={disposalLocationToView.website} className="text-link" target="_blank" rel="noreferrer">
                      <p className="p-smallest">
                        {disposalLocationToView.website.length < 50
                          ? disposalLocationToView.website
                          : disposalLocationToView.website.substring(0, 50)}
                      </p>
                    </a>
                  </ContactMode>
                )}
              </Contact>
              {disposalLocationToView?.openingHours && (
                <Hours className="p-smaller">
                  <SectionTitle className="SectionTitle-smaller">Open hours</SectionTitle>
                  <table className="color-light">
                    <tbody>
                      {disposalLocationToView.openingHours.map((detail, key) => (
                        <tr key={`TR-${key}`}>
                          <OpenHoursDetail key={`OH-${key}`}>{detail}</OpenHoursDetail>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Hours>
              )}
            </ContactHours>
          </CentreDetails>
        </div>
      )}
    </Modal>
  );
};

const LoaderContainer = styled.div`
  text-align: center;
  padding: 25px;
  width: 100%;
  height: 100px;
`;

const PlaceImage = styled(_PlaceImage)`
  width: 100%;
  height: 440px;
  position: relative;
  bottom: 60px;

  @media screen and (max-width: 768px) {
    height: 262px;
  }
`;

const CloseButton = styled.img`
  position: relative;
  top: 20px;
  left: 300px;
  cursor: pointer;
  z-index: 2;

  @media screen and (max-width: 768px) {
    top: 15px;
    left: 160px;
    width: 26px;
  }
`;

const CentreDetails = styled.div`
  text-align: left;
  padding: 40px 30px;
  padding-top: 0px;
`;

const LocationName = styled.h4`
  font-family: ${Theme.Fonts.SANS_1_900};
`;

const ContactHours = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Contact = styled.div`
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ContactMode = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.p`
  font-family: ${Theme.Fonts.SANS_1_900};
`;

const Hours = styled.div`
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const OpenHoursDetail = styled.td`
  padding-right: 20px;
`;

export default DisposalLocationDetailsModal;
