import { AlertAction } from '../actions';
import { AlertActionType } from '../action-types';

type Alert = {
  msg: string;
  alertType: string;
  id: string;
};

type AlertState = Alert[];

const initialState: Alert[] = [];

const reducer = (state: AlertState = initialState, action: AlertAction): AlertState => {
  switch (action.type) {
    case AlertActionType.SET_ALERT:
      return [action.payload];
    case AlertActionType.REMOVE_ALERT:
      return state.filter(alert => alert.id !== action.payload);
    default:
      return state;
  }
};

export default reducer;
