import React from 'react';
import styled from 'styled-components';

import ACORLogo from '../assets/images/ACORLogo.png';
import AusGovLogo from '../assets/images/AusGovLogo.png';
import AdaptationLogo from '../assets/images/AdaptationLogo.png';
import Theme from '../utils/theme';

const Community: React.FC = () => {
  return (
    <CommunityContainer>
      <Title>The Recycle Mate Community</Title>
      <Description>
        <DescriptionText className="color-darker">Do you ever feel confused about recycling?</DescriptionText>
        <DescriptionText className="color-darker">
          We are empowering Australian communities to be part of the recycling solution, but we can't do it without you.
          Recycle Mate brings you a dynamic platform that allows our governments, recyclers and the whole community to
          all work together to gather and share recycling information.
        </DescriptionText>
        <DescriptionText className="color-darker">
          When we get a tricky recycling question we find the answer and share it with everyone. Chances are, if one
          person is asking, many people may appreciate the answer.
        </DescriptionText>
        <DescriptionText className="color-darker">
          Every photo taken using the Recycle Mate app and every question asked helps us continually grow Recycle Mate -
          helping your mates!{' '}
        </DescriptionText>
      </Description>
      <PartnerLogosContainer>
        <ACORLogoImg src={ACORLogo} alt="ACORLogo" />
        <AusGovLogoImg src={AusGovLogo} alt="AusGovLogo" />
        <AdaptationLogoImg src={AdaptationLogo} alt="AdaptationLogo" />
      </PartnerLogosContainer>
      <SubTextContainer>
        <p className="color-darker p-small">
          Recycle Mate is an initiative of the Australian Council of Recycling with funding support from the Australian
          Government's Environment Restoration Fund program. Adaptation Environmental Support is the program delivery
          partner.
        </p>
      </SubTextContainer>
    </CommunityContainer>
  );
};

const CommunityContainer = styled.section`
  max-width: 1440px;
  margin: auto;
  text-align: center;
  padding: 50px;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  margin-bottom: 50px;
  font-family: ${Theme.Fonts.SANS_1_700};
`;

const Description = styled.div`
  width: 60%;
  margin: auto;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const DescriptionText = styled.p`
  margin-bottom: 25px;
`;

const PartnerLogosContainer = styled.div`
  width: 75%;
  margin: auto;
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const ACORLogoImg = styled.img`
  width: 32%;

  @media screen and (max-width: 768px) {
    width: 70%;
    margin-bottom: 20px;
  }
`;

const AdaptationLogoImg = styled.img`
  width: 32%;

  @media screen and (max-width: 768px) {
    width: 70%;
    margin-bottom: 20px;
  }
`;

const AusGovLogoImg = styled.img`
  width: 32%;

  @media screen and (max-width: 768px) {
    width: 70%;
    margin-bottom: 20px;
  }
`;

const SubTextContainer = styled.div`
  width: 60%;
  margin: auto;
  padding-top: 30px;

  @media screen and (max-width: 768px) {
    width: 80%;
    padding-top: 20px;
  }
`;

export default Community;
