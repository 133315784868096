import React from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import BinSystemSelectionModal from './components/BinSystemSelectionModal';
import CouncilSelectionModal from './components/CouncilSelectionModal';
import Map from './features/map/containers/Map';
import Home from './pages/Home';
import Results from './pages/Results';
import TermsConditions from './pages/TermsConditions';
import { persistor, store } from './state/store';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Route
            path=""
            render={({ location }) => {
              if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
                ReactGA.pageview(location.pathname);
              }

              return null;
            }}
          />
          <Switch>
            <Route path="/map/:id?">
              <Map />
            </Route>
            <Route path="/results">
              <Results />
            </Route>
            <Route path="/items/:itemCode">
              <Results />
            </Route>
            <Route path="/i/:itemCode">
              <Results />
            </Route>
            <Route path="/q/:linkId">
              <Results />
            </Route>
            <Route path="/terms-conditions">
              <TermsConditions />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
        <CouncilSelectionModal />
        <BinSystemSelectionModal />
      </PersistGate>
    </Provider>
  );
};

export default App;
