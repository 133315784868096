import {
  GooglePlacesApiOptions,
  GooglePlacesAutocompleteProps,
  GooglePlacesAutocompletionRequest,
} from '../state/types';
import { SEARCH_DEBOUNCE_MILLISECONDS } from './constants';

// Google autocomplete options
export const googlePlacesApiOptions: GooglePlacesApiOptions = {
  language: 'en',
  region: 'AU',
};

export const autocompletionRequest: GooglePlacesAutocompletionRequest = {
  types: ['address'],
  componentRestrictions: { country: 'au' },
};

export const googlePlacesAutocompleteProps: GooglePlacesAutocompleteProps = {
  apiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
  apiOptions: googlePlacesApiOptions,
  minLengthAutocomplete: 3,
  debounce: SEARCH_DEBOUNCE_MILLISECONDS,
  autocompletionRequest: autocompletionRequest,
};

export const googlePlacesAutocompleteStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  option: () => ({
    borderBottom: '1px #fff solid',
    color: '#fff',
    backgroundColor: '#444a4e',
    cursor: 'pointer',
    border: '0',
    padding: '0 50px 0 10px',
    alignItems: 'left',
    textAlign: 'left',
    ':active': {
      backgroundColor: '#4c5256',
    },
    height: '50px',
    '@media screen and (max-width: 768px)': {
      ':first-of-type': {
        marginTop: '10px',
      },
      padding: '10px 10px 0 10px',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      padding: '5px 10px 5px 10px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '0.8rem',
    },
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'left',
    backgroundColor: '#444a4e',
    padding: '0 50px 0 10px',
    color: '#fff',
    borderRadius: '40px',
    width: '500px',
    height: '60px',
    outline: 'none',
    fontSize: '1rem',
    '@media screen and (max-width: 768px)': {
      width: '336px',
      height: '44px',
      fontSize: '0.8rem',
      padding: '10px 5px 10px 5px',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      width: '100%',
    },
  }),
  input: () => ({
    color: '#fff',
    padding: '0px 0 0 50px',
    '@media screen and (max-width: 768px)': {
      padding: '0px 0px 0px 35px',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      padding: '0px 0 0 50px',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    top: '45%',
    padding: '5px 0 0 50px',
    '@media screen and (max-width: 768px)': {
      padding: '0px 0 0 35px',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      padding: '0px 0 0 50px',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '40px',
    backgroundColor: '#444a4e',
    color: '#fff',
    boxShadow: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    backgroundColor: '#444a4e',
    color: '#fff',
    padding: '0',
    border: '0',
    boxShadow: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '0.8rem',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    transition: 'opacity 300ms',
    color: '#fff',
    top: '45%',
    '@media screen and (max-width: 768px)': {
      top: '14px',
      width: '280px',
    },
    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
      top: '30px',
    },
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    position: 'absolute',
    zIndex: '10',
    color: '#fff',
    right: '15px',
    cursor: 'pointer',
    fill: 'green',
    width: '50px',
    ' > svg': {
      fill: 'transparent',
    },
  }),
};
