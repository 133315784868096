import React, { useEffect, useMemo, useState } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import NoBinIcon from '../assets/images/NoBinIcon.svg';
import { useSelector } from '../hooks/useSelector';
import { Bin } from '../state/types';
import { bagImageForBin, binForWasteType, BinLid, isBag as isBagBin, lottieForBin } from '../utils/helpers';
import Theme from '../utils/theme';

const RecyclingInfo: React.FC = () => {
  const { binSystem } = useSelector(state => state.council);
  const { item } = useSelector(state => state.item);

  const [binLottie, setBinLottie] = useState('');
  const [textLottie, setTextLottie] = useState('');
  const [bin, setBin] = useState<Bin>();

  const isNoBinSystem = useMemo(() => binSystem.length === 1 && binSystem[0].binType === BinLid.NONE, [binSystem]);
  const isBag = useMemo(() => isBagBin(bin), [bin]);
  const { bagImage, bagText } = useMemo(() => bagImageForBin(bin), [bin]);

  useEffect(() => {
    const selectedBin = binForWasteType(item?.wasteType, binSystem);
    const { lottieBin, lottieText } = lottieForBin(selectedBin);

    setBin(selectedBin);
    setBinLottie(lottieBin);
    setTextLottie(lottieText);
  }, [item, binSystem]);

  const lottieBinOptions = {
    loop: false,
    autoplay: true,
    animationData: binLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const lottieTextOptions = {
    loop: false,
    autoplay: true,
    animationData: textLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <RecyclingInfoContainer>
      <TitleContainer className="bg-brush">
        <Title>Recycling information</Title>
      </TitleContainer>
      {isNoBinSystem && (
        <>
          <NoBins src={NoBinIcon} alt="No Bin" />
          <p>It doesn't look like you have any kerbside bins.</p>
        </>
      )}
      {!isNoBinSystem && (
        <GraphicContainer isBag={isBag}>
          {isBag ? (
            <>
              <BagImage src={bagImage} />
              <BagText src={bagText} />
            </>
          ) : (
            <>
              <LottieContainerText>
                <Lottie options={lottieTextOptions} height={370} width={350} />
              </LottieContainerText>
              <Lottie options={lottieBinOptions} height={350} width={350} />
            </>
          )}
        </GraphicContainer>
      )}
      <ItemTitle>{item?.name}</ItemTitle>
      <p className="p-smaller color-dark">{item?.message}</p>
    </RecyclingInfoContainer>
  );
};

const RecyclingInfoContainer = styled.div`
  margin: 30px 0px;
`;

const TitleContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-family: ${Theme.Fonts.SANS_1_900};
  margin-bottom: 0px;
`;

const GraphicContainer = styled.div<{ isBag?: boolean }>`
  width: 100%;
  height: ${({ isBag }) => (isBag ? 220 : 370)}px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: ${({ isBag }) => (isBag ? '130px' : '0')};
`;

const LottieContainerText = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
`;

const ItemTitle = styled.h1`
  margin: 30px 0px;
  font-family: ${Theme.Fonts.SANS_1_700};
`;

const NoBins = styled.img`
  width: 120px;
  height: 120px;
`;

const BagImage = styled.img`
  position: absolute;
  height: 200px;
`;

const BagText = styled.img`
  position: absolute;
  height: 100px;
  top: -110px;
`;

export default RecyclingInfo;
