import React from 'react';
import styled from 'styled-components';

import BinBoxes from '../assets/images/BinBoxes.svg';
import GiftFemale from '../assets/images/GiftFemale.svg';
import AppStoreButton from '../assets/images/AppStoreButtonLight.svg';
import GooglePlayButton from '../assets/images/GooglePlayButtonLight.svg';

import { APPLE_STORE_LINK, GOOGLE_PLAY_LINK } from '../utils/constants';
import Theme from '../utils/theme';

const Overview: React.FC = () => {
  return (
    <OverviewContainer id="overview">
      <BinBoxesContainer>
        <img src={BinBoxes} className="full-width" alt="BinBoxes" />
      </BinBoxesContainer>
      <OverviewDetails>
        <OverviewTitle className="color-darkest">Scan all types of items around the house</OverviewTitle>
        <OverviewText className="color-darker">
          Recycle Mate helps you recycle anywhere in Australia – whether at home, at a friend's house or when on
          holiday. Just take a photo or type the name of an item you would like to know more about and Recycle Mate will
          give you disposal advice specific to your location.
        </OverviewText>
        <AppDownloadButtonsContainer>
          <a href={APPLE_STORE_LINK} target="_blank" rel="noreferrer">
            <img src={AppStoreButton} alt="AppStoreButton" />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a href={GOOGLE_PLAY_LINK} target="_blank" rel="noreferrer">
            <img src={GooglePlayButton} alt="GooglePlayButton" />
          </a>
        </AppDownloadButtonsContainer>
      </OverviewDetails>
      <GiftFemaleContainer>
        <img src={GiftFemale} className="full-width" alt="GiftFemale" />
      </GiftFemaleContainer>
    </OverviewContainer>
  );
};

const OverviewContainer = styled.section`
  max-width: 1440px;
  margin: auto;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 30px;
    padding: 20px;
    padding-bottom: 0px;
  }
`;

const BinBoxesContainer = styled.div`
  width: 25%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const OverviewDetails = styled.div`
  width: 50%;
  padding: 20px;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 50px 0px;
  }
`;

const OverviewTitle = styled.h1`
  font-family: ${Theme.Fonts.SANS_1_700};
`;

const OverviewText = styled.p`
  margin-top: 50px;
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

const AppDownloadButtonsContainer = styled.div`
  margin-top: 40px;
`;

const GiftFemaleContainer = styled.div`
  width: 25%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export default Overview;
