import axios from 'axios';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { placeViewed } from '../../api';
import { AlertActionType, ItemActionType } from '../action-types';
import { AlertAction, ItemAction } from '../actions';
import { DisposalLocation, Item, LocationLatLng } from '../types';

export const searchItem = (itemSearchTerm: string) => async (dispatch: Dispatch<ItemAction>) => {
  dispatch({
    type: ItemActionType.SEARCH_ITEM_PENDING,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/items/search?term=${itemSearchTerm}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      },
    );
    dispatch({
      type: ItemActionType.SET_ITEM_RESULTS,
      payload: res.data,
    });
  } catch (error: any) {
    dispatch({
      type: ItemActionType.SEARCH_ITEM_ERROR,
      payload: error.message,
    });
  }
};

export const setItem = (item: Item) => async (dispatch: Dispatch<ItemAction>) => {
  dispatch({
    type: ItemActionType.SET_ITEM,
    payload: item,
  });
};

export const resetItem = () => async (dispatch: Dispatch<ItemAction>) => {
  dispatch({
    type: ItemActionType.RESET_ITEM,
  });
};

export const getDisposalLocations =
  (itemId: string, councilId?: string, locationLatLng?: LocationLatLng) => async (dispatch: Dispatch<ItemAction>) => {
    dispatch({
      type: ItemActionType.GET_DISPOSAL_LOCATIONS_PENDING,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/items/destinations`,
        {
          itemId,
          councilId,
          location: locationLatLng,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        },
      );

      if (Array.isArray(res.data.destinations)) {
        const automationIndex = res.data.destinations.findIndex((e: DisposalLocation) => e.type === 'Automation');

        if (automationIndex !== -1) {
          const categoryResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/places/category`,
            {
              category: res.data.destinations[automationIndex].category.toLowerCase().replace(' ', '_'),
              location: locationLatLng,
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
              },
            },
          );

          if (categoryResponse.data) {
            categoryResponse.data.forEach(async (element: string) => {
              const placeResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/places/details?placeId=${element}`,
                {
                  headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
                  },
                },
              );

              dispatch({
                type: ItemActionType.ADD_DISPOSAL_LOCATION,
                payload: { ...placeResponse.data, facilityMessage: 'Drop off here' },
              });
            });
          }

          dispatch({
            type: ItemActionType.FINISH_LOADING,
          });
        } else {
          // first response without preview images, added facility message to response
          dispatch({
            type: ItemActionType.SET_DISPOSAL_LOCATIONS,
            payload: res.data,
          });
        }
      } else {
        dispatch({
          type: ItemActionType.SET_DISPOSAL_LOCATIONS,
          payload: { destinations: [] },
        });
        dispatch({
          type: ItemActionType.FINISH_LOADING,
        });
      }
    } catch (error: any) {
      dispatch({
        type: ItemActionType.GET_DISPOSAL_LOCATIONS_ERROR,
        payload: error.message,
      });
    }
  };

export const getDisposalLocationsForCouncil =
  (mapNumber?: string, zoomLevel?: number, coordinates?: string) => async (dispatch: Dispatch<ItemAction>) => {
    dispatch({
      type: ItemActionType.GET_DISPOSAL_LOCATIONS_FOR_COUNCIL_PENDING,
    });

    try {
      const queryParams =
        `${mapNumber ? `&mapNumber=${mapNumber}` : ''}` +
        `${zoomLevel ? `&zoomLevel=${zoomLevel}` : ''}` +
        `${coordinates ? `&coordinates=${coordinates}` : ''}`;

      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/location/disposal-locations?${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        },
      );

      if (Array.isArray(res.data.destinations)) {
        dispatch({
          type: ItemActionType.SET_DISPOSAL_LOCATIONS_FOR_COUNCIL,
          payload: res.data,
        });

        dispatch({
          type: ItemActionType.FINISH_LOADING,
        });
      }
    } catch (error: any) {
      dispatch({
        type: ItemActionType.GET_DISPOSAL_LOCATIONS_FOR_COUNCIL_ERROR,
        payload: error.message,
      });
    }
  };

export const setDisposalLocationToView =
  (disposalLocation: DisposalLocation) => async (dispatch: Dispatch<ItemAction>) => {
    placeViewed(disposalLocation._id);

    dispatch({
      type: ItemActionType.SET_DISPOSAL_LOCATION_TO_VIEW,
      payload: disposalLocation,
    });
  };

export const resetDisposalLocationToView = () => async (dispatch: Dispatch<ItemAction>) => {
  dispatch({
    type: ItemActionType.RESET_DISPOSAL_LOCATION_TO_VIEW,
  });
};

export const addItem = (itemName: string, councilName?: string) => async (dispatch: Dispatch<AlertAction>) => {
  await axios.put(
    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/items/new?name=${encodeURIComponent(
      itemName,
    )}&council=${encodeURIComponent(councilName ?? '')}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    },
  );

  const id = uuidv4();
  dispatch({
    type: AlertActionType.SET_ALERT,
    payload: {
      msg: 'Item added successfully',
      alertType: 'success',
      id,
    },
  });

  setTimeout(() => dispatch({ type: AlertActionType.REMOVE_ALERT, payload: id }), 5000);
};

export const getItem = (id: string) => async (dispatch: Dispatch<ItemAction>) => {
  dispatch({
    type: ItemActionType.GET_ITEM_PENDING,
  });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/items/code/${id}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      },
    );
    dispatch({
      type: ItemActionType.SET_ITEM,
      payload: res.data,
    });
  } catch (error: any) {
    dispatch({
      type: ItemActionType.GET_ITEM_ERROR,
      payload: error.message,
    });
  }
};

export const getLink = (id: string) => async (dispatch: Dispatch<ItemAction>) => {
  dispatch({ type: ItemActionType.GET_ITEM_PENDING });

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/links/${id}`);

    dispatch({ type: ItemActionType.SET_ITEM, payload: res.data?.items?.[0] });
  } catch (error: unknown) {
    dispatch({
      type: ItemActionType.GET_ITEM_ERROR,
      // @ts-ignore
      payload: error?.message,
    });
  }
};
