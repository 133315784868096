import axios from 'axios';
import { PlacesMap } from './types';

enum Endpoints {
  COUNCIL_SELECTED = '/metrics/council-selection',
  BIN_SYSTEM_SELECTED = '/metrics/bin-system-selection',
  ITEM_VIEWED = '/metrics/item-instruction',
  PLACE_VIEWED = '/metrics/waste-destination-selection',
  PLACES_SUGGESTED = '/metrics/waste-destination-suggestions',
  DISPOSAL_LOCATIONS = '/location/disposal-locations',
}

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
  },
});

export const placeViewed = (wasteDestinationId: string, wasteTicketId?: string) =>
  api.post(Endpoints.PLACE_VIEWED, { wasteDestinationId, wasteTicketId, platform: 'web' });

export const placesSuggested = (suggestions: { wasteDestinationId: string; wasteTicketId: string }[]) =>
  api.post(Endpoints.PLACES_SUGGESTED, { suggestions, platform: 'web' });

export const councilSelected = (councilId: string, flow: 'address' | 'geolocation') =>
  api.post(Endpoints.COUNCIL_SELECTED, { councilId, flow, platform: 'web' });

export const binSystemSelected = (councilId: string, binSystemNumber: number) =>
  api.post(Endpoints.BIN_SYSTEM_SELECTED, { councilId, binSystemNumber, platform: 'web' });

export const itemViewed = (itemId: string, councilId: string, flow: 'ai' | 'search', binSystemId?: string) =>
  api.post(Endpoints.ITEM_VIEWED, { itemId, councilId, binSystemId, flow, platform: 'web' });

export const getMap = async (id?: string, siteletId?: string) => {
  const url = siteletId ? `/location/maps?siteletId=${siteletId}` : `/location/maps/${id}`;

  return (await api.get<PlacesMap>(url)).data;
};
