import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { binSystemSelected } from '../api';
import NoBinIcon from '../assets/images/NoBinIcon.svg';
import { useActions } from '../hooks/useActions';
import { useSelector } from '../hooks/useSelector';
import { BinLid, BinType, imageForBin, sortBins, textDescriptionForBin, WasteType } from '../utils/helpers';
import Theme from '../utils/theme';

const BinSystemSelectionModal: React.FC = () => {
  const { setBinSystem } = useActions();
  const { binSystem, binSystemCandidates, council } = useSelector(state => state.council);

  const [isBinSystemSelectionModalOpen, setIsBinSystemSelectionModalOpen] = useState(false);
  const [selectedBinSystemKey, setSelectedBinSystemKey] = useState(0);

  const handleBinSystemSelection = () => {
    const selectedBinSystem = binSystemCandidates[selectedBinSystemKey];

    if (selectedBinSystem) {
      if (council?.id) {
        binSystemSelected(council.id, selectedBinSystem[0].system);
      }

      setBinSystem(selectedBinSystem);
    }
  };

  const candidates = useMemo(
    () => binSystemCandidates.sort((a, b) => b.length - a.length).map(v => sortBins(v)),
    [binSystemCandidates],
  );

  useEffect(() => {
    if (binSystem.length === 0 && binSystemCandidates.length > 0) {
      setIsBinSystemSelectionModalOpen(true);
    }
  }, [binSystem, binSystemCandidates]);

  useEffect(() => {
    if (binSystem.length > 0) {
      setIsBinSystemSelectionModalOpen(false);
    }
  }, [binSystem]);

  return (
    <Modal
      isOpen={isBinSystemSelectionModalOpen}
      className="modal"
      overlayClassName="modal-overlay"
      contentLabel="Bin System Selection Modal"
      ariaHideApp={false}>
      <div>
        <ModalTitle>What bins do you have?</ModalTitle>
        <Location>
          <LocationText>Please confirm which bins you have at your address</LocationText>
        </Location>
        <Selection>
          {candidates.map((candidate, key) => (
            <SelectOption
              selected={selectedBinSystemKey === key}
              onClick={() => setSelectedBinSystemKey(key)}
              key={`OP-${key}`}>
              <Bins>
                {(candidate[0]?.binType === BinLid.YELLOWSPLIT
                  ? [{ ...candidate[0], wasteType: 'General waste/recycling' }]
                  : candidate
                ).map((bin, k) =>
                  bin.wasteType !== WasteType.NONE ? (
                    <Bin key={`BN-${k}`}>
                      <BinImage src={imageForBin(bin)} />
                      <Label className="p-smallest">{textDescriptionForBin(bin.wasteType as BinType)}</Label>
                    </Bin>
                  ) : (
                    <Bin key={`BN-${k}`}>
                      <img src={NoBinIcon} alt="No Bin" />
                    </Bin>
                  ),
                )}
              </Bins>
              {candidate[0].wasteType === WasteType.NONE && (
                <p className="p-smallest">No kerbside bins at my location</p>
              )}
            </SelectOption>
          ))}
        </Selection>
        <Suggestion>Are your bins not listed here?</Suggestion>
        <SuggestionLink href={process.env.REACT_APP_AIRTABLE_FORM} target="_blank">
          Let us know &nbsp; ➜
        </SuggestionLink>
        <ConfirmButton className="btn btn-big bg-darkgreen" onClick={handleBinSystemSelection}>
          Confirm
        </ConfirmButton>
      </div>
    </Modal>
  );
};

const ModalTitle = styled.h2`
  margin-bottom: 20px;
  line-height: 2.5rem;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const LocationText = styled.div`
  margin-left: 10px;
  font-family: ${Theme.Fonts.SANS_1_400};

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const Selection = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ConfirmButton = styled.button`
  font-family: ${Theme.Fonts.SANS_1_700};
`;

const SelectOption = styled.div<{ selected?: boolean }>`
  width: 515px;
  border-radius: 10px;
  background-color: #f4f5f5;
  color: #000;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  border: 2px solid ${({ selected }) => (selected ? '#99c753' : '#f4f5f5')};

  :hover {
    border: 2px solid #99c753;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Bins = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
`;

const Bin = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 80px;
  }
`;

const BinImage = styled.img`
  width: 100px;

  @media screen and (max-width: 768px) {
    width: 50px;
  }
`;

const Label = styled.p`
  margin: 12px 0 0 0;
  height: 35px;

  @media screen and (max-width: 768px) {
    height: 35px;
  }
`;

const Suggestion = styled.p`
  color: rgba(48, 60, 66, 0.6);
  font-weight: 400;
  margin-bottom: 0;
`;

const SuggestionLink = styled.a`
  font-family: ${Theme.Fonts.SANS_1_700};
  color: #437060;
  margin-bottom: 24px;
  cursor: pointer;
  display: block;

  :hover {
    text-decoration: underline;
  }
`;

export default BinSystemSelectionModal;
