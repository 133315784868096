import React from 'react';
import styled from 'styled-components';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import Underline from '../assets/images/Underline.png';

const TermsConditions: React.FC = () => {
  return (
    <React.Fragment>
      <header>
        <Navbar isHome={false} />
      </header>
      <MainContainer>
        <Title>Terms & Conditions</Title>
        <UnderlineImg src={Underline} alt="" />
        <Content>
          <p>
            Thank you for visiting our site. This site is owned and operated by Adaptation Environmental Support Pty Ltd
            ABN 14 114 922 191. By accessing and/or using this site and related services, you agree to these Terms and
            Conditions, which include our Privacy Policy available at www.recyclemate.com.au. You should review our
            Privacy Policy and these Terms carefully and immediately cease using our site if you do not agree to these
            Terms.{' '}
          </p>
          <p>In these Terms, 'us', 'we' and 'our' means Adaptation Environmental Support and Recycle Mate.</p>
          <p className="bold">1. Accuracy, completeness and timeliness of information</p>
          <p>
            The information on our site is not comprehensive and is intended to provide a summary of the subject matter
            covered. While we use all reasonable attempts to ensure the accuracy and completeness of the information on
            our site, to the extent permitted by law, including the Australian Consumer Law, we make no warranty
            regarding the information on this site. You should monitor any changes to the information contained on this
            site.
          </p>
          <p>
            We are not liable to you or anyone else if interference with or damage to your computer systems occurs in
            connection with the use of this site or a linked site. You must take your own precautions to ensure that
            whatever you select for your use from our site is free of viruses or anything else (such as worms or Trojan
            horses) that may interfere with or damage the operations of your computer systems.
          </p>
          <p>
            We may, from time to time and without notice, change or add to the site (including the Terms) or the
            information, products or services described in it. However, we do not undertake to keep the site updated. We
            are not liable to you or anyone else if errors occur in the information on the site or if that information
            is not up-to-date.
          </p>
          <p className="bold">2. Linked sites</p>
          <p>
            Our site may contain links to sites operated by third parties. Those links are provided for convenience and
            may not remain current or be maintained. Unless expressly stated otherwise, we do not endorse and are not
            responsible for the content on those linked sites and have no control over or rights in those linked sites.{' '}
          </p>
          <p className="bold">3. Intellectual property rights</p>
          <p>
            Unless otherwise indicated, we own or license from third parties all rights, title and interest (including
            copyright, designs, patents, trademarks and other intellectual property rights) in this site and in all of
            the material (including all text, graphics, logos, audio and software) made available on this site (
            <em>Content</em>
            ).{' '}
          </p>
          <p>
            Your use of this site and use of and access to any Content does not grant or transfer any rights, title or
            interest to you in relation to this site or the Content. However we do grant you a licence to access the
            site and view the Content on the terms and conditions set out in this Agreement and, where applicable, as
            expressly authorised by us and/or our third party licensors.
          </p>
          <p>
            Any reproduction or redistribution of this site or the Content is prohibited and may result in civil and
            criminal penalties. In addition, you must not copy the Content to any other server, location or support for
            publication, reproduction or distribution is expressly prohibited.{' '}
          </p>
          <p>
            All other use, copying or reproduction of this site, the Content or any part of it is prohibited, except to
            the extent permitted by law.
          </p>
          <p className="bold">4. No commercial use</p>
          <p>
            This site is for your personal, non-commercial use only. You may not modify, copy, distribute, transmit,
            display, perform, reproduce, publish, license, commercially exploit, create derivative works from, transfer,
            or sell any Content, software, products or services contained within this site. You may not use this site,
            or any of its Content, to further any commercial purpose, including any advertising or advertising revenue
            generation activity on your own site.
            <br /> <br /> Any images submitted by you will be added to the app training pool where it will be used for
            further training.&nbsp; All uploaded images will be accessible to the app development team for the sole
            purpose of app training.
          </p>
          <p className="bold">5. Unacceptable activity</p>
          <p>
            You must not do any act that we would deem to be inappropriate, is unlawful or is prohibited by any laws
            applicable to our site, including but not limited to:
          </p>
          <ul>
            <li>
              any act that would constitute a breach of either the privacy (including uploading private or personal
              information without an individual's consent) or any other of the legal rights of individuals;
            </li>
            <li>using this site to defame or libel us, our employees or other individuals;</li>
            <li>
              uploading files that contain viruses that may cause damage to our property or the property of other
              individuals;
            </li>
            <li>
              posting or transmitting to this site any non-authorised material including, but not limited to, material
              that is, in our opinion, likely to cause annoyance, or which is defamatory, racist, obscene, threatening,
              pornographic or otherwise or which is detrimental to or in violation of our systems or a third party's
              systems or network security.{' '}
            </li>
          </ul>
          <p>
            If we allow you to post any information to our site, we have the right to take down this information at our
            sole discretion and without notice.
            <br />{' '}
          </p>
          <p className="bold">6. Warranties and disclaimers</p>
          <p>
            To the maximum extent permitted by law, including the Australian Consumer Law, we make no warranties or
            representations about this site or the Content, including but not limited to warranties or representations
            that they will be complete, accurate or up-to-date, that access will be uninterrupted or error-free or free
            from viruses, or that this site will be secure.
          </p>
          <p>
            We reserve the right to restrict, suspend or terminate without notice your access to this site, any Content,
            or any feature of this site at any time without notice and we will not be responsible for any loss, cost,
            damage or liability that may arise as a result.{' '}
          </p>
          <p className="bold">7. Liability</p>
          <p>
            To the maximum extent permitted by law, including the Australian Consumer Law, in no event shall we be
            liable for any direct and indirect loss, damage or expense &ndash; irrespective of the manner in which it
            occurs &ndash; which may be suffered due to your use of our site and/or the information or materials
            contained on it, or as a result of the inaccessibility of this site and/or the fact that certain information
            or materials contained on it are incorrect, incomplete or not up-to-date.
          </p>
          <p className="bold">8. Jurisdiction and governing law</p>
          <p>
            Your use of the site and these Terms are governed by the law of New South Wales and you submit to the
            non-exclusive jurisdiction of the courts exercising jurisdiction in NSW.
          </p>
        </Content>
      </MainContainer>
      <Footer isHome={false} />
    </React.Fragment>
  );
};

const MainContainer = styled.main`
  max-width: 1440px;
  padding: 50px 150px;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 50px;
  }
`;

const Title = styled.h1`
  margin-top: 70px;
  margin-bottom: 20px;
`;

const UnderlineImg = styled.img`
  margin-bottom: 50px;
`;

const Content = styled.div`
  text-align: left;
`;

export default TermsConditions;
