import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, MapLayerMouseEvent, MapRef, default as ReactMap, Source } from 'react-map-gl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as api from '../../../api';
import { ReactComponent as Logo } from '../../../assets/images/Logo.svg';
import PlaceModal from '../../../components/PlaceModal';
import { ClusterFeature, Place, PlaceFeature } from '../../../types';
import { adjustPlaceCoordinates, alertParentFromIframe, getQuery } from '../../../utils/helpers';
import Key from '../components/Key';
import Popup from '../components/Popup';

// Mapbox have compatibility issues when transpiling with webpack
// for this reason we need to use worker-loader to load the worker
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

const DEFAULT_BOUNDS: LngLatBoundsLike = [
  [113.338953078, -43.6345972634],
  [153.569469029, -10.6681857235],
];

const MIN_DISTANCE_METRES = 20;

const isPlaceFeature = (feature: mapboxgl.MapboxGeoJSONFeature): feature is PlaceFeature =>
  feature?.layer.id === 'places';

const isClusterFeature = (feature: mapboxgl.MapboxGeoJSONFeature): feature is ClusterFeature =>
  feature?.layer.id === 'clusters';

const getIconType = (type?: string) => {
  switch (type) {
    case 'Waste Facility':
    case 'Commercial Recycler':
      return 'recycle-station';
    default:
      return type?.toLowerCase().replace(/ /g, '-');
  }
};

const MapView: FC = () => {
  const params = useParams<{ id?: string }>();

  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [features, setFeatures] = useState<GeoJSON.Feature[]>([]);
  const [featuresIdFilter, setFeaturesIdFilter] = useState<string[] | undefined>();
  const [hoveredFeature, setHoveredFeature] = useState<PlaceFeature>();
  const [mapBounds, setMapBounds] = useState<LngLatBoundsLike>(DEFAULT_BOUNDS);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isSiteletMode, setIsSiteletMode] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [showKey, setShowKey] = useState(true);
  const [mouseOverFeature, setMouseOverFeature] = useState(false);
  const [mouseOverPopup, setMouseOverPopup] = useState(false);
  const [mapId, setMapId] = useState<string>();
  const [siteletId, setSiteletId] = useState<string>();

  const mapRef = useRef<MapRef>(null);

  const filteredFeatures = useMemo(
    () => (featuresIdFilter ? features.filter(f => featuresIdFilter.includes(f.properties?.id ?? '')) : features),
    [features, featuresIdFilter],
  );

  useEffect(() => {
    const queryParams = getQuery();

    setIsSiteletMode(queryParams['mode'] === 'sitelet');
    setShowLogo(!queryParams['hideLogo']);
    setShowKey(!queryParams['hideKey']);
    setSiteletId(queryParams['siteletId']);
  }, []);

  const onLogoClick = useCallback(() => {
    window.open('https://recyclemate.com.au', '_blank');
  }, []);

  const setCursor = useCallback((cursor: string) => {
    const canvas = mapRef.current?.getCanvas();

    if (!canvas) {
      return;
    }

    canvas.style.cursor = cursor;
  }, []);

  const getPlaceForFeature = useCallback(
    (feature: PlaceFeature) =>
      features.find(f => f.properties?.id === feature.properties.id)?.properties as Place | undefined,
    [features],
  );

  const handleClusterClick = useCallback((feature: ClusterFeature) => {
    if (!mapRef.current) {
      return;
    }

    const clusterId = feature.properties.cluster_id;
    const geometry = feature.geometry;

    const source = mapRef.current.getSource('places') as mapboxgl.GeoJSONSource;

    source.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      mapRef.current?.easeTo({
        center: geometry.coordinates as [number, number],
        zoom: zoom,
      });
    });
  }, []);

  const onMapLoad = useCallback(() => {
    setMapLoaded(true);
  }, []);

  const onMapClick = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!mapRef.current || !e.features?.length) {
        return;
      }

      const feature = e.features[0];

      if (isClusterFeature(feature)) {
        handleClusterClick(feature);
      } else if (isPlaceFeature(feature)) {
        const place = features.find(f => f.properties?._id === feature.properties._id)?.properties as Place;

        if (isSiteletMode) {
          alertParentFromIframe(JSON.stringify(place));
        } else {
          setSelectedPlace(place);
        }
      }
    },
    [features, handleClusterClick, isSiteletMode],
  );

  const onMapMouseEnter = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!mapRef.current || !e.features?.length) {
        return;
      }

      setCursor('pointer');

      if (isPlaceFeature(e.features[0])) {
        setHoveredFeature(e.features[0]);
        setMouseOverFeature(true);
      }
    },
    [setCursor],
  );

  const onMapMouseLeave = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!mapRef.current || !e.features?.length) {
        return;
      }

      setCursor('grab');

      const feature = e.features[0];

      if (isPlaceFeature(feature)) {
        setMouseOverFeature(false);
      }
    },
    [setCursor],
  );

  const onMapMouseOver = useCallback(() => {
    setMouseOverPopup(false);
  }, []);

  const onPopupMouseEnter = useCallback(() => {
    setMouseOverPopup(true);
  }, []);

  const onPopupClick = useCallback(
    (feature: PlaceFeature) => {
      const place = getPlaceForFeature(feature);

      if (isSiteletMode) {
        alertParentFromIframe(JSON.stringify(place));
      } else {
        setSelectedPlace(place);
      }
    },
    [getPlaceForFeature, isSiteletMode],
  );

  const loadData = useCallback(async () => {
    const data = await api.getMap(mapId, siteletId).then(d => ({
      ...d,
      wasteDestinations: adjustPlaceCoordinates(
        d.wasteDestinations.filter(v => v.location?.coordinates),
        MIN_DISTANCE_METRES,
      ),
    }));

    const iconSources: Record<string, string | undefined> = {};

    if (data.itemIcons?.length) {
      data.wasteDestinations.forEach(place => {
        const placeIcons = data.itemIcons?.filter(icon => place.itemIcons?.includes(icon._id));

        iconSources[place.id] = placeIcons
          ?.map(icon => `${icon.name}~data:${icon.type};base64,${icon.base64}`)
          .join('|');
      });
    }

    setFeatures(
      data.wasteDestinations.map(place => ({
        type: 'Feature',
        geometry: place.location,
        properties: {
          ...place,
          iconType: getIconType(place.type),
          itemIcons: iconSources[place.id],
        } as PlaceFeature['properties'],
      })),
    );

    setMapBounds(data.bounds.coordinates);
  }, [mapId, siteletId]);

  const onWindowMessage = useCallback((e: MessageEvent) => {
    switch (e.data.type) {
      case 'mapId':
        setMapId(e.data.payload);

        break;
      case 'placesFilter': {
        const placeIds = e.data.payload as string[] | undefined;

        setFeaturesIdFilter(placeIds);
        break;
      }
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(loadData, 500);

    return () => clearTimeout(timeout);
  }, [loadData]);

  useEffect(() => {
    if (mapLoaded) {
      mapRef.current?.fitBounds(mapBounds);
    }
  }, [mapBounds, mapLoaded]);

  useEffect(() => {
    if (window) {
      window.addEventListener('message', onWindowMessage);
    }
    return () => {
      window.removeEventListener('message', onWindowMessage);
    };
  }, [onWindowMessage]);

  useEffect(() => {
    if (!mouseOverFeature) {
      const timeout = setTimeout(() => {
        if (!mouseOverPopup) {
          setHoveredFeature(undefined);
        }
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [mouseOverFeature, mouseOverPopup]);

  useEffect(() => {
    if (params.id !== undefined) {
      setMapId(params.id);
    }
  }, [params.id]);

  return (
    <Container>
      <MapBox
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE_DARK}
        initialViewState={{ bounds: DEFAULT_BOUNDS }}
        interactiveLayerIds={['clusters', 'places']}
        onClick={onMapClick}
        onMouseEnter={onMapMouseEnter}
        onMouseLeave={onMapMouseLeave}
        onMouseOver={onMapMouseOver}
        onLoad={onMapLoad}>
        <Source id="places" type="geojson" data={{ type: 'FeatureCollection', features: filteredFeatures }} cluster>
          <Layer
            id="clusters"
            type="circle"
            filter={['has', 'point_count']}
            paint={{
              'circle-color': '#ded4c5',
              'circle-radius': ['step', ['get', 'point_count'], 22, 100, 30, 750, 40],
            }}
          />
          <Layer
            id="cluster-count"
            type="symbol"
            filter={['has', 'point_count']}
            layout={{
              'text-field': ['get', 'point_count_abbreviated'],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            }}
          />
          <Layer
            id="places"
            type="symbol"
            filter={['!', ['has', 'point_count']]}
            layout={{
              'icon-image': ['get', 'iconType'],
              'icon-size': 0.35,
            }}
          />
        </Source>
        {!!hoveredFeature && (
          <Popup feature={hoveredFeature} onTitleClick={onPopupClick} onMouseEnter={onPopupMouseEnter} />
        )}
      </MapBox>
      {showKey && (
        <KeyContainer>
          {showLogo && <Logo onClick={onLogoClick} />}
          <Key />
        </KeyContainer>
      )}
      <PlaceModal place={selectedPlace} visible={!!selectedPlace} onRequestClose={() => setSelectedPlace(undefined)} />
    </Container>
  );
};

export default MapView;

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const MapBox = styled(ReactMap)`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
`;

const KeyContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(12px);
  padding: 18px 18px 8px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 16px;

  svg {
    cursor: pointer;
    margin-bottom: 18px;
  }
`;
